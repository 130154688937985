import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { getCustomData } from "./FetchApi";
import { Button, useMediaQuery } from "@mui/material";
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterOptions from "./FilterOptions";

const CustomData = (props) => {
  const { customStone, setCustomStone, setNewOne, newOne } = props;
  const [fetchData, setFetchData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [customData, setCustomData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [checkedState, setCheckedState] = useState([]);
  const [type, setType] = useState('NATURAL');
  const [tKeys, setTKeys] = useState([]);
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState({});
  const isMobile = useMediaQuery('(max-width:768px)');

  const StoneTypeButton = ({ label, color, isActive, onClick }) => (
    <button
      onClick={onClick}
      className={`
        px-4 py-2 rounded-md font-semibold text-white transition-all
        ${color === 'blue' ? 'bg-blue-500 hover:bg-blue-600' : 'bg-green-500 hover:bg-green-600'}
        ${isActive ? 'border-2 border-orange-500' : 'border-2 border-transparent'}
        focus:outline-none focus:border-2 focus:border-${color}-700
      `}
    >
      {label}
    </button>
  );

  const fetchCustomData = async (type, pageNumber, searchTerm, filters) => {
    setType(type);
    setPageNumber(pageNumber);
    let resp = await getCustomData(type, (pageNumber * 20), searchTerm, filters);
    if (resp.data.length) {
      setTKeys(Object.keys(resp.data[0]));
    }
    setFetchData(resp.data);
    setTotal(resp.count);
    setCustomData(resp.data);
  };

  useEffect(() => {
    fetchCustomData(type, pageNumber, searchTerm, filters);
  }, [type, pageNumber, searchTerm, filters]);

  useEffect(() => {
    setCheckedState(customData.map((a) => ({
      ["Sr No"]: a['Sr No'],
      checked: a["Sr No"] === customStone["Sr No"]
    })));
  }, [customData, customStone]);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleRadioButton = (s) => {
    const updatedCheckedState = checkedState.map((item) =>
      item["Sr No"] === s
        ? { ...item, checked: true }
        : { ...item, checked: false }
    );
    setCheckedState(updatedCheckedState);

    const selectedStone = customData.find(stone => stone["Sr No"] === s);
    if (selectedStone) {
      setCustomStone({
        price: Math.ceil(selectedStone["Net Amt(Rs)"]),
        ["Sr No"]: selectedStone["Sr No"],
        data: selectedStone
      });
      setNewOne(selectedStone);
    } else {
      setNewOne({});
    }
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setPageNumber(0);  // Reset to first page when filters change
  };

  return (
    <>
      <div className="flex flex-col justify-center space-y-1">
        <h4 className="font-bold text-lg">Choose Your Center Stone</h4>
        {newOne && Object.keys(newOne).length > 0 && (
          <div className="overflow-x-auto mt-4 p-4 bg-gray-100 rounded shadow fade-in">
            <h2 className="text-lg font-semibold mb-2">
              Center Stone Selected
            </h2>
            <table className="w-full table-auto border-collapse">
              <thead>
                <tr>
                  <th className="border px-4 py-2">Carat</th>
                  <th className="border px-4 py-2">Color</th>
                  <th className="border px-4 py-2">Clarity</th>
                  <th className="border px-4 py-2">Certificate Number</th>
                  <th className="border px-4 py-2">Stone ID</th>
                  <th className="border px-4 py-2">Net Rate</th>
                  <th className="border px-4 py-2">Type</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border px-4 py-2">{newOne.Cts}</td>
                  <td className="border px-4 py-2">{newOne.Color}</td>
                  <td className="border px-4 py-2">{newOne.Clarity}</td>
                  <td className="border px-4 py-2">{newOne["Certi No"]}</td>
                  <td className="border px-4 py-2">{newOne["Stock Id"]}</td>
                  <td className="border px-4 py-2">
                    {parseFloat(newOne["Net Amt(Rs)"]).toFixed(2)}
                  </td>
                  <td className="border px-4 py-2">{type}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div id="centerStoneTable" className="my-3 flex flex-col md:flex-row gap-4 md:justify-between items-center">
        <div className="flex flex-col space-y-1 w-full md:w-auto">
          <input
            id="searchStone"
            className="bg-gray-200 rounded p-2 outline-none w-full md:w-64"
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search Stone"
          />
        </div>
        <div className="flex flex-wrap gap-2 justify-center md:justify-start">
          <StoneTypeButton
            label="Lab Grown Diamonds"
            color="blue"
            isActive={type === 'LAB'}
            onClick={() => setType('LAB')}
          />
          <StoneTypeButton
            label="Natural Diamonds"
            color="green"
            isActive={type === 'NATURAL'}
            onClick={() => setType('NATURAL')}
          />
        </div>
        <Button
          variant="outlined"
          startIcon={<FilterListIcon />}
          onClick={() => {
            setShowFilter(!showFilter);
            showFilter && handleFilterChange({});
          }}
        >
          Filter
        </Button>
      </div>
      {showFilter && (
        <FilterOptions onFilterChange={handleFilterChange} isMobile={isMobile} />
      )}
      <div className="w-full">
        {!fetchData.length > 0 ? (
          <div className="flex flex-col justify-center text-center">
            <h4 className="text-center">
              No Stones available
            </h4>
            <div className="m-auto">
              <div className="dot-flashing"></div>
            </div>
          </div>
        ) : (
          <>
            <div className="w-full overflow-x-auto">
              <Table className="text-xs table-auto" striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Select Stone</th>
                    <th className="sticky-left-new">Sr No</th>
                    {tKeys.filter((s) => !s.match(/_id|is_deleted|created_on|updated_on|Sr No|Stock Id|Certi No|Cr Ang|Cr Ht|Crown Black|Crown White|Culet|Girdle|Key To Symbol|Pav Ang|Pav Ht/)).map((a, i) => <th key={i}>{a}</th>)}
                  </tr>
                </thead>
                <tbody>
                  {customData.map((a, index) => (
                    <tr
                      onClick={() => handleRadioButton(a["Sr No"])}
                      key={a._id}
                      className={newOne["Sr No"] === a["Sr No"] ? "border-2 border-blue-600" : ""}
                    >
                      <td>
                        <input
                          id={`radio${a["Sr No"]}`}
                          type="radio"
                          value={a["Sr No"]}
                          checked={newOne["Sr No"] === a["Sr No"]}
                          onChange={() => handleRadioButton(a["Sr No"])}
                        />
                      </td>
                      <td className={`sticky-left-new ${newOne["Sr No"] === a["Sr No"] ? "border-y-2 border-blue-600" : ""}`}>{a["Sr No"]}</td>
                      {Object.entries(a).map(([y, x], i) =>
                        y.match(/_id|is_deleted|created_on|updated_on|Sr No|Stock Id|Certi No|Cr Ang|Cr Ht|Crown Black|Crown White|Culet|Girdle|Key To Symbol|Pav Ang|Pav Ht/)
                          ? null
                          : <td key={i}>{typeof x === 'object' ? <a href={x.url} rel="noopener noreferrer" target="_blank">{x.text}</a> : x}</td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="flex flex-row justify-center text-center text-xs mt-3">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={Math.ceil(total / 20)}
                forcePage={pageNumber}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CustomData;