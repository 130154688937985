import React, { Fragment, useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {paymentCallBack} from "./FetchApi"
import { Snackbar,Alert } from '@mui/material';
const PayOnline = (props)=>{  

   const displayRazorPay = () =>{  

       console.log("razor id",props.razorpayData.razorpay_order_id)
    var options = {
        "key": process.env.REACT_APP_RAZORPAY_ID, // Enter the Key ID generated from the Dashboard
        "amount": props.razorpayData.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        "currency": props.razorpayData.currency,
        "name": props.razorpayData.name,
        "description": "Test Transaction",
        "order_id": props.razorpayData.razorpay_order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        "handler": async function (response){
          let paymentStatus = await  paymentCallBack(response)
          console.log(response)
          if(response.success === "ok"){  
            props.setPaymentStatus("paid")
            props.setCheckoutPage(2)
          }
          else{  
            props.setPaymentStatus("failed")
            props.setCheckoutPage(2)
          }
        },
        "prefill": {
            "name": props.razorpayData.name,
            "email": props.razorpayData.email,
            "contact": "+91"+props.razorpayData.phone,

        },
        "notes": {
            "address": "H&M Designs Rayzorpay Payment"
        },
        "theme": {
            "color": "#3399cc"
        }
    };
    var rzp1 = new window.Razorpay(options);
    console.log(rzp1)
        rzp1.on('payment.failed', function (response){
            props.setPaymentStatus("failed")
                // alert(response.error.code);
                // alert(response.error.description);
                // alert(response.error.source);
                // alert(response.error.step);
                // alert(response.error.reason);
                // alert(response.error.metadata.order_id);
                // alert(response.error.metadata.payment_id);
        });
        rzp1.open()
    }
    useEffect(()=>{
        props.razorpayData && props.razorpayData.order_id && props.paymentMode === 1 && displayRazorPay()
    },[props.razorpayData.order_id])

    return null
// return (<>{props.paymentStatus === "paid" && <Snackbar anchorOrigin={{ vertical:"top",horizontal: "right" }} open={true} autoHideDuration={6000} onClose={()=>null}>
// <Alert onClose={()=>null} severity="success" sx={{ width: '100%' }}>
//   Payment Successful
// </Alert>
// </Snackbar>}
// {props.paymentStatus === "failed" && <Snackbar anchorOrigin={{ vertical:"top",horizontal: "right" }} open={true} autoHideDuration={6000} onClose={()=>null}>
// <Alert onClose={()=>null} severity="danger" sx={{ width: '100%' }}>
//   Payment Failed
// </Alert>
// </Snackbar>}
// </>)
}
export default PayOnline;