import React, { Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Spinner } from "react-bootstrap";
import Slider from "react-slick";
import { getAllCustomImages, getAllSec1Images } from "./fetchApi";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import Carousel from "react-material-ui-carousel";
import styles from "./home.module.css";
const Home = () => {
  const [images, setImages] = useState([]);
  const [sec1Images, setSec1Images] = useState([]);
  const getAllImages = async () => {
    let data = await getAllCustomImages();
    let sec1Img = await getAllSec1Images();
    if (data) {
      setImages([...data.Images]);
      sec1Img && setSec1Images([...sec1Img.Images]);
    }
  };
  useEffect(() => {
    getAllImages();
  }, []);
  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    cssEase: "linear",
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Home | Find Your Favorite Jewellery | H&M Designs | Fine Diamond
          Jewellery & Diamond Watches
        </title>
        <link rel="canonical" href={`/products`} />
      </Helmet>
      <section className="p-3 hidden md:block">
        {images.length > 0 ? (
          // <Slider {...settings} className=" w-full">
          //   {images
          //     .filter((a) => a.slider == 1)
          //     .map((i) => (
          //       <a href={i.link} target="_blank" key={i._id}>
          //         <img
          //           src={i.image}
          //           className=" h-96 sm:h-44 w-full cursor-pointer object-fill object-center"
          //         />
          //       </a>
          //     ))}
          // </Slider>
          <Carousel
            className=" w-full"
            autoPlay={true}
            swipe={true}
            indicators={false}
          >
            {images
              .filter((a) => a.slider === 1)
              .map((i) => (
                <a
                  href={i.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={i._id}
                >
                  <img
                    alt="banners"
                    src={i.image}
                    style={{ height: "34rem" }}
                    className="w-full cursor-pointer object-fill object-center"
                  />
                </a>
              ))}
          </Carousel>
        ) : (
          <div class="flex h-96">
            <div class="m-auto">
              <Spinner animation="border" role="status"></Spinner>
            </div>
          </div>
        )}
      </section>
      <section className="p-3 md:hidden">
        {images.length > 0 ? (
          <Slider {...settings} className=" w-full">
            {images
              .filter((a) => a.slider == 1)
              .map((i) => (
                <a href={i.link} target="_blank" key={i._id}>
                  <img
                    src={i.mob}
                    className=" h-96 w-full cursor-pointer object-fill object-center"
                  />
                </a>
              ))}
          </Slider>
        ) : (
          <div class="flex h-96">
            <div class="m-auto">
              <Spinner animation="border" role="status"></Spinner>
            </div>
          </div>
        )}
      </section>

      <section className="p-3 text-left max-w-4xl mx-auto text-center">
        <h1 className="text-4xl font-semibold">Welcome to H&M Designs</h1>
        <p className="text-base">
          Discover the elegance of diamond-studded fine jewellery at H&M
          Designs. We offer a stunning range of exquisite pieces that blend
          timeless beauty with modern sophistication.
        </p>
      </section>

      <section className="p-3">
        <h2 className="text-2xl font-semibold">Our Products </h2>
        <hr></hr>
        <h3 className="text-xl">Fine Jewellery & Accessories:</h3>
        <p className="text-base">
          Explore our collection of diamond-studded fine jewellery, as below.
          Enhance your style with our Solid Stainless Steel Diamond Studded
          Chronograph Watches, combining luxury and functionality in one
          exquisite timepiece.
        </p>
      </section>
      <section>
        <div class="grid grid-cols-1 p-3 md:grid-cols-2 gap-4 mt-3">
          {sec1Images
            .filter((a) => a.section == 1)
            .map((s) => (
              <>
                <a href={s.link} target="_blank">
                  <img
                    className=" w-full cursor-pointer object-fill object-center rounded-2"
                    src={s.image}
                  ></img>
                </a>
              </>
            ))}
        </div>
      </section>
      <section className={`p-3  text-center text-white ${styles.descText}`}>
        <hr></hr>
        <p className="text-base">
          Natural diamonds have a unique appeal due to their rarity and natural
          formation over millions of years. Therefore it holds intrinsic value
          and rarity, making them a timeless investment. Wearing something that
          has been formed naturally over time, is seen as a symbol of enduring
          love or commitment. People still perceive natural diamonds as having
          greater value, both monetarily and sentimentally, as they hold
          significance, especially in cultural or familial contexts.
        </p>
        <hr></hr>
      </section>
      <section className="p-3 hidden md:block">
        {images.length > 0 ? (
          <Slider {...settings} className=" w-full">
            {images
              .filter((a) => a.slider == 2)
              .map((i) => (
                <a href={i.link} target="_blank" key={i._id}>
                  <img
                    src={i.image}
                    style={{ height: "34rem" }}
                    className=" h-96 sm:h-44 w-full cursor-pointer object-fill object-center"
                  />
                </a>
              ))}
          </Slider>
        ) : (
          <div class="flex h-96">
            <div class="m-auto">
              <Spinner animation="border" role="status"></Spinner>
            </div>
          </div>
        )}
      </section>
      <section className="p-3 md:hidden">
        {images.length > 0 ? (
          <Slider {...settings} className=" w-full">
            {images
              .filter((a) => a.slider == 2)
              .map((i) => (
                <a href={i.link} target="_blank" key={i._id}>
                  <img
                    src={i.mob}
                    className=" h-96 w-full cursor-pointer object-fill object-center"
                  />
                </a>
              ))}
          </Slider>
        ) : (
          <div class="flex h-96">
            <div class="m-auto">
              <Spinner animation="border" role="status"></Spinner>
            </div>
          </div>
        )}
      </section>
      <section className="p-3">
        <h2 className="text-4xl font-semibold">Why Choose Us? </h2>
        <hr></hr>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="p-4 bg-blue-100 rounded shadow-md">
            <h3 className="text-2xl">Quality Craftsmanship:</h3>
            <p className="text-base">
              Each piece is crafted with meticulous attention to detail,
              ensuring exceptional quality, with each one hand sketched before
              CAD designing.
            </p>
          </div>
          <div className="p-4 bg-yellow-100 rounded shadow-md">
            <h3 className="text-2xl">Variety:</h3>
            <p className="text-base">
              From classic designs to contemporary styles, we offer something
              for every taste, with both online products and offline inventory
              to choose from.
            </p>
          </div>
          <div className="p-4 bg-red-100 rounded shadow-md">
            <h3 className="text-2xl">Customization Options:</h3>
            <p className="text-base">
              Tailor your jewellery to your preferences with options for
              different gold karats, diamond qualities and select your own
              centre solitaire diamond, creating a piece that perfectly reflects
              your style and budget.
            </p>
          </div>
          <div className="p-4 bg-green-100 rounded shadow-md">
            <h3 className="text-2xl">Certification:</h3>
            <p className="text-base">
              Certified by a reputable gemological laboratory, all our diamond
              jewellery is hallmarked for purity by an official assay office and
              ethically sourced through conflict-free means and fair labour
              practices.
            </p>
          </div>
        </div>
      </section>
      <section
        style={{
          backgroundImage:
            'url("https://hnm-designs-image.s3.ap-south-1.amazonaws.com/1645380586141_pexels-the-glorious-studio-9953655.jpg")',
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        }}
      >
        <div class="grid grid-cols-1 p-3 md:grid-cols-2 gap-4 mt-3">
          {sec1Images
            .filter((a) => a.section == 2)
            .map((s) => (
              <>
                <a href={s.link} target="_blank">
                  <img
                    className=" w-full cursor-pointer object-fill object-center rounded-2"
                    src={s.image}
                  ></img>
                </a>
              </>
            ))}
        </div>
      </section>
      <section>
        <div class="grid grid-cols-1 p-3 md:grid-cols-2 gap-4 mt-3">
          {sec1Images
            .filter((a) => a.section == 3)
            .map((s) => (
              <>
                <a href={s.link} target="_blank">
                  <img
                    className=" w-full cursor-pointer object-fill object-center rounded-2"
                    src={s.image}
                  ></img>
                </a>
              </>
            ))}
        </div>
      </section>
      <section>
        <div class="grid grid-cols-1 p-3 md:grid-cols-2 gap-4 mt-3">
          {sec1Images
            .filter((a) => a.section == 4)
            .map((s) => (
              <>
                <a href={s.link} target="_blank">
                  <img
                    className=" w-full cursor-pointer object-fill object-center rounded-2"
                    src={s.image}
                  ></img>
                </a>
              </>
            ))}
        </div>
      </section>
    </>
  );
};

export default Home;
