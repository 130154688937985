import React, { Fragment, useState, useContext, useEffect } from "react";
import {
  loginReq,
  googleLoginReq,
  facebookLoginReq,
  forgotPasswordReq,
} from "./fetchApi";
import { useNavigate } from "react-router-dom";
import { LayoutContext } from "../index";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import InstagramLogin from "react-instagram-login";
import { toast } from "react-toastify";

const Login = (props) => {
  const toastId = React.useRef(null);
  const history = useNavigate();
  const { data: layoutData, dispatch: layoutDispatch } =
    useContext(LayoutContext);
  const [showPassword, setShowPassword] = useState(false);

  const [data, setData] = useState({
    email: "",
    password: "",
    rememberMe: false,
    error: false,
    loading: true,
  });
  const [forgotEmail, setForgotEmail] = useState({
    email: "",
    error: false,
    loading: true,
    message: "",
  });
  const [lostpassword, setLostpassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const alert = (msg) => <div className="text-xs text-red-500">{msg}</div>;
  const LoginFormSubmit = async () => {
    setData({ ...data, loading: true });

    try {
      let responseData = await loginReq({
        email: data.email,
        password: data.password,
      });
      console.log(responseData);
      if (responseData.error) {
        setData({
          ...data,
          loading: false,
          error: responseData.error,
          password: "",
        });
        return new Promise((resolve, reject) => setTimeout(reject, 1000));
      } else if (responseData.token) {
        setData({ email: "", password: "", loading: false, error: false });
        localStorage.setItem("jwt", JSON.stringify(responseData));

        layoutDispatch({ type: "loginSignupModalToggle", payload: false });
        layoutDispatch({ type: "logedinUser", payload: true });
        history("/");
        return new Promise((resolve) => setTimeout(resolve, 2000));
      } else {
        return new Promise((resolve, reject) => setTimeout(reject, 1000));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCheckboxChange = (event) => {
    setRememberMe(true);
    const { name, value, type, checked } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  useEffect(() => {
    const rememberMeValue = localStorage.getItem("rememberMe") === "true";
    const savedEmail = localStorage.getItem("email") || "";
    const savedPassword = localStorage.getItem("password") || "";

    setData((prevData) => ({
      ...prevData,
      rememberMe: rememberMeValue,
      email: rememberMeValue ? savedEmail : "",
      password: rememberMeValue ? savedPassword : "",
      loading: false,
    }));
  }, []);
  const formSubmit = async () => {
    if (data.rememberMe) {
      localStorage.setItem("rememberMe", "true");
      localStorage.setItem("email", data.email);
      localStorage.setItem("password", data.password);
    } else {
      localStorage.removeItem("rememberMe");
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    }
    toast.promise(LoginFormSubmit, {
      pending: "Logging in ... ",
      success: "Login Successful 👍",
      error: "Login failed 😢",
    });
  };

  const forgotPasswordEmail = async () => {
    console.log(forgotEmail.message);
    toast.promise(forgotPassHandler, {
      pending: "Sending reset link ... ",
      success: `Please check your email👍`,
      error: `Please Enter Valid Email`,
    });
  };

  const forgotPassHandler = async () => {
    setForgotEmail({ ...forgotEmail, loading: true });
    try {
      let responseData = await forgotPasswordReq({
        email: forgotEmail.email,
      });

      if (!responseData.error) {
        console.log(responseData);
        setForgotEmail({
          email: "",
          loading: false,
          error: false,
          message: responseData.message,
        });
        return new Promise((resolve) => setTimeout(resolve, 2000));
      } else {
        setForgotEmail({
          ...forgotEmail,
          loading: false,
          error: responseData.error,
        });
        return new Promise((reject) => setTimeout(reject, 2000));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const responseGoogleLogin = async (response) => {
    let responseData = await googleLoginReq(response.tokenId);
    console.log(responseData);
    if (responseData && responseData.error) {
      setData({
        ...data,
        loading: false,
        error: responseData.error,
        password: "",
      });
    } else if (responseData && responseData.token) {
      localStorage.setItem("jwt", JSON.stringify(responseData));
      layoutDispatch({ type: "loginSignupModalToggle", payload: false });
      layoutDispatch({ type: "logedinUser", payload: true });
      history("/");
      toast.success("Google Login Successful", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const responseFacebookLogin = async (response) => {
    let responseData = await facebookLoginReq({
      accessToken: response.accessToken,
      userID: response.userID,
    });
    if (responseData.error) {
      setData({
        ...data,
        loading: false,
        error: responseData.error,
        password: "",
      });
    } else if (responseData.token) {
      localStorage.setItem("jwt", JSON.stringify(responseData));
      layoutDispatch({ type: "loginSignupModalToggle", payload: false });
      layoutDispatch({ type: "logedinUser", payload: true });
      history("/");
      toast.success("Facebook Login Successful", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const responseInstagramLogin = async (response) => {
    console.log(response);
  };

  const lostpasswordLink = () => {
    props.setLoginValue("Login");
    // props.setLogin(true);
    setLostpassword(true);
  };
  if (lostpassword) {
    return (
      <Fragment>
        <div className="text-center text-2xl mb-6">Lost Password</div>
        <form className="space-y-4">
          <div className="flex flex-col">
            <label htmlFor="name">
              Email address
              <span className="text-sm text-gray-600 ml-1">*</span>
            </label>
            <input
              onChange={(e) => {
                setForgotEmail({
                  ...forgotEmail,
                  email: e.target.value,
                  error: false,
                });
                layoutDispatch({ type: "loginSignupError", payload: false });
              }}
              value={forgotEmail.email}
              type="text"
              id="name"
              className={`${
                !forgotEmail.error ? "" : "border-red-500"
              } px-4 py-2 focus:outline-none border`}
            />
            {!forgotEmail.error ? "" : alert(forgotEmail.error)}
          </div>

          <div
            onClick={(e) => forgotPasswordEmail()}
            style={{ background: "#303031" }}
            className="font-medium px-4 py-2 text-white text-center cursor-pointer"
          >
            Reset Password
          </div>
        </form>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <div className="text-center text-2xl mb-6">Login</div>
        {layoutData.loginSignupError ? (
          <div className="bg-red-200 py-2 px-4 rounded">
            You need to login for checkout. Haven't accont? Create new one.
          </div>
        ) : (
          ""
        )}
        <form className="space-y-4">
          <div className="flex flex-col">
            <label htmlFor="name">
              Username or email address
              <span className="text-sm text-gray-600 ml-1">*</span>
            </label>
            <input
              onChange={(e) => {
                setData({ ...data, email: e.target.value, error: false });
                layoutDispatch({ type: "loginSignupError", payload: false });
              }}
              value={data.email}
              type="text"
              id="name"
              className={`${
                !data.error ? "" : "border-red-500"
              } px-4 py-2 focus:outline-none border`}
            />
            {!data.error ? "" : alert(data.error)}
          </div>
          <div className="flex flex-col">
            <label htmlFor="password">
              Password<span className="text-sm text-gray-600 ml-1">*</span>
            </label>
            <input
              onChange={(e) => {
                setData({ ...data, password: e.target.value, error: false });
                layoutDispatch({ type: "loginSignupError", payload: false });
              }}
              value={data.password}
              type={showPassword ? "text" : "password"}
              id="password"
              className={`${
                !data.error ? "" : "border-red-500"
              } px-4 py-2 focus:outline-none border`}
            />
            <button
              type="button"
              onClick={handlePasswordVisibility}
              className="absolute inset-y-0 right-0 px-2"
              style={{ paddingBottom: "2.5rem" }}
            >
              {showPassword ? "Hide" : "Show"}
            </button>
            {!data.error ? "" : alert(data.error)}
          </div>
          {/* <div className="flex flex-col space-y-2 flex-row justify-between items-center">
            <div>
              <GoogleLogin
                autoLoad={false}
                clientId={
                  process.env.GOOGLE_CLIENT_ID ||
                  "824730245538-qpf2jf6u90pnvr7qackm0cd7guia4jcr.apps.googleusercontent.com"
                }
                buttonText="Login"
                onSuccess={responseGoogleLogin}
                onFailure={responseGoogleLogin}
              />
            </div>
            <div>
              <FacebookLogin
                appId={process.env.FACEBOOK_APP_ID || "1949820088566605"}
                textButton="Login"
                autoLoad={false}
                callback={responseFacebookLogin}
                cssClass="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                icon="fa-facebook mr-3"
              />
              <InstagramLogin
                clientId="5fd2f11482844c5eba963747a5f34556"
                buttonText="Login"
                onSuccess={responseInstagramLogin}
                onFailure={responseInstagramLogin}
              /> 
            </div>
          </div> */}
          <div className="flex flex-col space-y-2 md:flex-row md:justify-between md:items-center">
            <div>
              {/* <input
                type="checkbox"
                id="rememberMe"
                className="px-4 py-2 focus:outline-none border mr-1"
                checked={rememberMe}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="rememberMe">Remember me</label> */}
            </div>
            <a
              className="block text-gray-600"
              href="#"
              onClick={lostpasswordLink}
            >
              Forgot Password?
            </a>
          </div>
          <div
            onClick={(e) => {
              formSubmit();
              navigator.vibrate(30);
            }}
            className="font-medium px-4 py-2 text-white text-center cursor-pointer bg-gray-700 hover:bg-gray-800 rounded"
          >
            Login
          </div>
        </form>
      </Fragment>
    );
  }
};

export default Login;
