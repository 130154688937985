import axios from "axios";
// const apiURL = process.env.REACT_APP_API+'/';
const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`,
    },
  };
};
export const getSingleProduct = async (data) => {
  try {
    let res = await axios.post(`/api/product/single-product`, {
      data,
    });
    console.log(res.data)
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const fetchColor = async (pid)=>{  
  try{  
    let res = await axios.get(`/api/product/fetch-color`);
    return res.data;
  }
  catch(err){  
    console.log(err)
  }
}
export const postAddReview = async (formData) => {
  try {
    let res = await axios.post(`/api/product/add-review`, formData,Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const postDeleteReview = async (formData) => {
  try {
    let res = await axios.post(`/api/product/delete-review`, formData,Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};


export const getCustomData = async (type, skip, searchTerm, filters) => {
  try {
    // Create a new URLSearchParams object to build the query string
    const params = new URLSearchParams({
      type: type,
      skip: skip
    });

    // Add searchTerm if it exists
    if (searchTerm) {
      params.append('searchTerm', searchTerm);
    }

    // Add filters if they exist
    if (filters) {
      if (filters.priceRange) {
        params.append('minPrice', filters.priceRange[0]);
        params.append('maxPrice', filters.priceRange[1]);
      }
      if (filters.caratRange) {
        params.append('minCarat', filters.caratRange[0]);
        params.append('maxCarat', filters.caratRange[1]);
      }
      if (filters.color && filters.color.length > 0) {
        params.append('color', filters.color.join(','));
      }
      if (filters.clarity && filters.clarity.length > 0) {
        params.append('clarity', filters.clarity.join(','));
      }
      if (filters.cut) {
        params.append('cut', filters.cut);
      }
    }

    // Construct the URL with the query string
    const url = `/api/centerStone/getCenterStones?${params.toString()}`;

    let result = await axios.get(url);
    console.log(result);
    return result.data;
  } catch (e) {
    console.log(e);
    throw e;  // Re-throw the error so it can be handled by the calling function
  }
};

export const addToCartServer = async (data) =>{
  try {
    let result = await axios.post(`/api/product/add-to-cart`,data,Headers())
    return result.data
  } catch (e) {
    console.log(e)
  }
}

export const getRelatedProducts = async (data) =>{
  try {
    let result = await axios.post(`/api/product/fetch-related-products`,data,Headers())
    return result.data
  } catch (e) {
    console.log(e)
  }
}
export const getPhotoPandentImages = async (id,setNow1) =>{
  try {
    let result = await axios.get(`/api/product/get-user-photopendant-image?id=`+id,{
      headers:(Headers()).headers,
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 50;
        setNow1(progress);
      },
      onDownloadProgress: (progressEvent) => {
        const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
        setNow1(progress);
      }
    })
    return result.data
  } catch (e) {
    console.log(e)
  }
}
export const generatePhotoPandent = async ({image,id},setNow) => {
  let formdata = new FormData();
  formdata.append("id",id)
  formdata.append("image",image)
  try {
    let res = await axios.post(`/api/product/create-photo-pendant-user-image`, formdata,{
    headers:(Headers()).headers,
    onUploadProgress: (progressEvent) => {
      const progress = (progressEvent.loaded / progressEvent.total) * 50;
      setNow(parseInt(progress));
    },
    onDownloadProgress: (progressEvent) => {
      const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
      setNow(parseInt(progress));
    }
  }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};