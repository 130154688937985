import React, { Fragment, useEffect,useState, useContext } from "react";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import Layout from "./Layout";
import { DashboardUserContext } from "./Layout";
import { getOrderByUser } from "./FetchApi";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button } from "@mui/material";
const OrdersComponent = () => {
  const history = useNavigate();
  const [orders,setOrders]= useState([])
  const fetchOrderByUser = async () => {
    let response = await getOrderByUser();
    setOrders(response.Order)
  };

  useEffect(() => {
    fetchOrderByUser();
  }, []);

  return (
    <>
      <div className="w-full md:w-2/5 mx-auto space-y-2">
        {orders.map((order, i) =>(  
          <div>
          <div className="w-full p-4 bg-gray-200 rounded-t hover:shadow">
            <div className="text-gray-500 font-bold text-sm">ORDER ID: {order.order_id}</div>
          <div className="flex justify-between font-bold">
            <div>Order Status </div>
            <div>Order Total ({order.products.length} Items)</div>
          </div>
          <div className="flex justify-between">
            <div>{order.status}, {moment(order.last_updated).format('LLL')}</div>
            <div>{order.total_amount}.00</div>
          </div>
          <div className="flex justify-between">
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={(e) => history('/user/orders/'+order._id)}
            >
              View Details
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={(e) => window.open(`https://api.whatsapp.com/send/?phone=%2B919833287775&text=Hi%2C+I+have+a+query+about+this+order+,+order ID+:+${order.order_id}+&type=phone_number&app_absent=0`)}
            >
              Need Help
            </Button>
          </div>
        </div>
        <div className="text-center text-xs bg-yellow-300 rounded-b border-red-500 text-red-500">
            {order.payment_mode==0 && "Cash on Delivery"} ({order.payment})
        </div>
        </div>
        
        ))}
        
      </div>
    </>
  );
};

const UserOrders = (props) => {
  return (
    <Fragment>
      <Layout children={<OrdersComponent />} />
    </Fragment>
  );
};

export default UserOrders;
