import axios from "axios";
// const apiURL = process.env.REACT_APP_API+'/';
const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`,
    },
  };
};

export const getLoginedUser = async () => {
  try {
    let response = await axios.get(
      `/api/user/fetchLoginedUser`,
      Headers()
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};
export const cartListProduct = async () => {
  let carts = JSON.parse(localStorage.getItem("cart")).sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );
  let productArray = [];
  let productDetails = [];
  if (carts) {
    for (const cart of carts) {
      productArray.push(cart.id);
      productDetails.push(cart);
    }
  }
  try {
    console.log(productDetails);
    let data = { productDetails, productArray };
    let res = await axios.post(
      `/api/product/cart-product`,
      {
        data,
      },
      Headers()
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const searchProduct = async (search) => {
  try {
    let res = await axios.get(
      `/api/product/search-product?search=${search}`
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
