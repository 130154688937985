import React, { Fragment, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { ProductDetailsContext } from "./index";
import { LayoutContext } from "../layout";
import Countdown from "react-countdown";
import Submenu from "./Submenu";
import ProductDetailsSectionTwo from "./ProductDetailsSectionTwo";
import ReactPlayer from "react-player";
import { BasicSpeedDial } from "../partials";
import { getSingleProduct, getCustomData, fetchColor } from "./FetchApi";
import Button from "@mui/material/Button";
import { cartListProduct } from "../partials/FetchApi";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import CustomData from "./customData";
import RelatedProducts from "./RelatedProducts";
import { isWishReq, unWishReq, isWish } from "../home/Mixins";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { convertToINR } from "../../utils/index";
import {
  updateQuantity,
  slideImage,
  addToCart,
  cartList,
  onSelectColor,
} from "./Mixins";
import PhotoPandentModal from "./photoPandentModal";
import ProductImage from "./productImage";
import { totalCost } from "../partials/Mixins";
import { Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import PriceTab from "../../custom/priceTab";
// const apiURL = process.env.REACT_APP_API + "/";
import SizeGuide from "./SizeGuide";
import './productDetails.css'

const ProductDetailsSection = (props) => {
  let { cat, subCat, id } = useParams();

  const { data, dispatch } = useContext(ProductDetailsContext);
  const { data: layoutData, dispatch: layoutDispatch } =
    useContext(LayoutContext); // Layout Context
  const [customStone, setCustomStone] = useState({
    SrNo: [],
    price: 0,
  });
  const [newOne, setNewOne] = useState({});
  const sProduct = layoutData.singleProductDetail;
  console.log("sProduct>>>>", newOne);
  const [pImages, setPimages] = useState(null);
  const [openSizeGuide, setOpenSizeGuide] = useState(false);
  const [count, setCount] = useState(0); // Slide change state
  const [quantitiy, setQuantitiy] = useState(1); // Increse and decrese quantity state
  const [alertQ, setAlertq] = useState(false); // Alert when quantity greater than stock
  const [colors, setColors] = useState([]);
  const [priceBreakUp, setPriceBreakUp] = useState({
    gPerGMPrice: 5,
    gMPrice: 0,
    gPerGPrice: 0,
    diamond: {},
    dMPrice: 0,
    dMOffer: 0,
    lMGPrice: 0,
    lMPrice: 0,
    lMOffer: 0,
    gPrice: 0,
    lPrice: 0,
  });
  const [photoPandent, setPhotoPandent] = useState(false);
  const [wList, setWlist] = useState(
    JSON.parse(localStorage.getItem("wishList"))
  );
  const [login, setLogin] = useState(window.localStorage.getItem("jwt"));
  // Wishlist State Control
  const [pData, setPData] = useState({
    weight: null,
    diamond: "61235059288b860023cd645a",
    carrett: "10",
    priceload: false,
    color: "",
  });
  const [pDetails, setPDetails] = useState({
    weight: null,
    diamond: "61235059288b860023cd645a",
    labourPrice: 0,
    carrett: "10",
  });

  useEffect(() => {
    fetchData();
  }, [pData.weight, pData.diamond, pData.carrett, id]);
  useEffect(() => {
    if (sProduct !== null) {
      console.log(sProduct);
      if (!sProduct.pCategory.sizeRequired) {
        setPDetails({
          ...pDetails,
          weight: sProduct.goldWeight + " g",
        });
      }
      console.log("siP>>>>", sProduct);
      sProduct.pCategory.sizeRequired &&
        setPDetails({
          ...pDetails,
          weight: sProduct.Size[0]?.weight,
        });

      if (sProduct.pCategory.sizeRequired && pData.weight) {
        setPDetails({
          ...pDetails,
          weight: sProduct?.Size?.reduce((a, b) => {
            if (b._id === pData.weight) {
              a = b.weight;
            }
            return a;
          }, ""),
        });
      }

      fetchColors();
    }
  }, [sProduct]);
  const [diamond, setDiamond] = useState([]);
  const fetchData = async () => {
    setPData({ ...pData, priceload: true });
    dispatch({ type: "loading", payload: false });
    try {
      let responseData = await getSingleProduct({ id, ...pData });
      console.log("the dat>>>>", responseData);
      setTimeout(() => {
        if (responseData.Product) {
          responseData.Product = { ...responseData.Product, ...pData };
          console.log(responseData.Product);
          layoutDispatch({
            type: "singleProductDetail",
            payload: responseData.Product,
          }); // Dispatch in layout context
          if (responseData.priceBreakUp) {
            let pBackup = responseData.priceBreakUp;
            if (pBackup.gPrice) {
              pBackup.gPerGPrice = pBackup.gPrice / pBackup.Weight;
              pBackup.gPerGMPrice = (pBackup.gPerGPrice * 100) / 80;
              pBackup.gMPrice = (pBackup.gPrice * 100) / 80;
            }
            if (pBackup.diamond?.mPrice) {
              pBackup.diamond.mMPrice = (pBackup.diamond?.mPrice * 100) / 80;
              pBackup.dMPrice = (pBackup.dPrice * 100) / 80;
              pBackup.dMOffer = Math.round(
                (((pBackup.dPrice * 100) / 80 - pBackup.discounteddprice) /
                  ((pBackup.dPrice * 100) / 80)) *
                100
              );
            }
            if (responseData.Product.LPrice) {
              pBackup.lMGPrice = (responseData.Product.LPrice * 100) / 80;
              pBackup.lMPrice = (pBackup.lPrice * 100) / 80;
              pBackup.lMOffer = Math.round(
                (((pBackup.lPrice * 100) / 80 - pBackup.discountedlprice) /
                  ((pBackup.lPrice * 100) / 80)) *
                100
              );
            }
            setPriceBreakUp(pBackup);
          } else {
            setPriceBreakUp(responseData.priceBreakUp);
          }
          setPimages(responseData.Product.pImages);
          setDiamond(responseData.diamonds);
          dispatch({ type: "loading", payload: false });
          layoutDispatch({ type: "inCart", payload: cartList() });
          // This function change cart in cart state
          setPData({ ...pData, priceload: false });
        }
        if (responseData.error) {
          setPData({ ...pData, priceload: true });

          console.log(responseData.error);
        }
      }, 500);
    } catch (error) {
      console.log(error);
    }
    fetchCartProduct();
  };

  const fetchCartProduct = async () => {
    try {
      let responseData = await cartListProduct();
      if (responseData && responseData.Products) {
        layoutDispatch({ type: "cartProduct", payload: responseData.Products }); // Layout context Cartproduct fetch and dispatch
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchColors = async () => {
    let response = await fetchColor();
    if (response.success) {
      let s = [];
      sProduct.pImages.map((i) => s.push(i.color));

      let filteredColor = response.data.filter((d) => {
        if (s.includes(d._id)) {
          return true;
        }
      });
      setPData({
        ...pData,
        color: filteredColor[0] ? filteredColor[0]._id : "",
        priceload: false,
      });
      setColors(filteredColor);
    }
  };

  const calculatePrice = (pPrice) => {
    if (pPrice) {
      if (sProduct.extraStones && sProduct.extraStones.length) {
        const extraStonePrice = sProduct.extraStones.reduce((a, c) => {
          if (c.replacable) {
            if (newOne["Net Amt(Rs)"]) {
              a = a + Number(newOne["Net Amt(Rs)"])
            } else {
              a = a + Number(c.stonePrice)
            }
          } else {
            a = a + Number(c.stonePrice)
          }
          return a
        }, 0)
        pPrice = pPrice + extraStonePrice
      } else {
        if (newOne["Net Amt(Rs)"]) {
          pPrice = pPrice + Number(newOne["Net Amt(Rs)"])
        }
      }
      return pPrice
    }
  }



  if (data.loading) {
    return (
      <div class="flex h-96">
        <div class="m-auto">
          <Spinner animation="border" role="status"></Spinner>
        </div>
      </div>
    );
  } else if (!sProduct) {
    return (
      <div class="flex h-96">
        <div class="m-auto">
          <Spinner animation="border" role="status"></Spinner>
        </div>
      </div>
    );
  }
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {`${sProduct.pName} | ${sProduct.pCategory.cName} | ${sProduct.pSubCategory.name}`}{" "}
          | H&M Designs | Fine Diamond Jewellery & Diamond Watches
        </title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Submenu
        value={{
          cat,
          id,
          subCat,
        }}
      />
      <SizeGuide open={openSizeGuide} setOpen={setOpenSizeGuide}></SizeGuide>
      <section className="m-4  md:mx-12 md:my-6 md:px-5">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div>
            <ProductImage
              count={count}
              setCount={setCount}
              images={sProduct.pImages}
            ></ProductImage>
          </div>
          {/* <div
            style={{ maxHeight: "35rem" }}
            className="hidden overflow-hidden md:block md:col-span-1 md:flex md:flex-col md:space-y-4 md:mr-2"
          >
            {sProduct.pImages.map((image, index) =>
              image.type == "photo" ? (
                <img
                  onMouseEnter={(e) =>
                    slideImage("increase", index, count, setCount, pImages)
                  }
                  key={index}
                  className={`${
                    count === index ? "" : "opacity-25"
                  } cursor-pointer w-20 h-20 object-cover object-center`}
                  src={image.url}
                  alt="pic"
                />
              ) : (
                <video
                  key={index}
                  onMouseEnter={(e) =>
                    slideImage("increase", index, count, setCount, pImages)
                  }
                  className={`${
                    count === index ? "" : "opacity-25"
                  } cursor-pointer w-20 h-20 object-cover object-center`}
                  alt="pic"
                >
                  <source src={image.url} type="video/mp4" />
                </video>
              )
            )}
          </div> */}
          {/* <div className="col-span-2 md:col-span-5">
            <div className="relative h-full">
              {sProduct.pImages.length > 0 && (
                <>
                  {sProduct.pImages[count].type == "photo" ? (
                    <img
                      style={{ maxHeight: "40rem" }}
                      className="w-full  object-cover object-center"
                      src={sProduct.pImages[count].url}
                      alt="Pic"
                    />
                  ) : (
                    <ReactPlayer
                      controls={true}
                      playIcon={`<i class="fas fa-play"></i>`}
                      className="w-full"
                      url={sProduct.pImages[count].url}
                      playing={true}
                    />
                  )}
                </>
              )}
              <div className="absolute inset-0 flex justify-between items-center mb-4">
                <svg
                  onClick={(e) =>
                    slideImage("increase", null, count, setCount, pImages)
                  }
                  className="flex justify-center  w-12 h-12 text-gray-700 opacity-25 cursor-pointer hover:text-yellow-700 hover:opacity-100"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
                <svg
                  onClick={(e) =>
                    slideImage("increase", null, count, setCount, pImages)
                  }
                  className="flex justify-center  w-12 h-12 text-gray-700 opacity-25 cursor-pointer hover:text-yellow-700 hover:opacity-100"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </div>
            </div>
          </div> */}
          {/* <PhotoPandentModal
            show={photoPandent}
            pid={sProduct._id}
            onHide={() => {
              setPhotoPandent(false);
            }}
          ></PhotoPandentModal> */}
          <div className=" mt-8 md:mt-0 md:ml-6 lg:ml-12">
            <div className="flex flex-col leading-8">
              <h1 className="text-xl md:text-2xl tracking-wider">
                {sProduct.pName}
              </h1>
              <div className="flex justify-between items-center">
                <span className="text-xl tracking-wider text-yellow-700">
                  {pData.priceload ? (
                    <div className="flex flex-row space-x-2">
                      <Spinner animation="grow" size="sm" />
                      <Spinner animation="grow" size="sm" />
                      <Spinner animation="grow" size="sm" />
                    </div>
                  ) : (
                    <>
                      {/* {`₹${parseInt(sProduct.pPrice) + customStone.price}.00`}{" "} */}
                      {convertToINR(
                        Math.ceil(calculatePrice(sProduct.pPrice))
                      )}
                      {"    "}

                      {convertToINR(Math.ceil((sProduct.pPrice * 100) / 80)) ==
                        "₹0" ? null : (
                        <span class="text-md text-red-500 line-through">
                          {/* ₹
                        {Math.ceil(
                          ((parseInt(sProduct.pPrice) + customStone.price) *
                            100) /
                            80
                        )}
                        .00 */}
                          {convertToINR(
                            Math.ceil(
                              (calculatePrice(sProduct.pPrice) * 100) / 80
                            )
                          )}
                        </span>
                      )}
                    </>
                  )}
                </span>
                <span>
                  <svg
                    onClick={(e) => isWishReq(e, sProduct._id, setWlist)}
                    className={`${isWish(sProduct._id, wList) && "hidden"
                      } w-5 h-5 md:w-6 md:h-6 cursor-pointer text-yellow-700`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                    />
                  </svg>
                  <svg
                    onClick={(e) => unWishReq(e, sProduct._id, setWlist)}
                    className={`${!isWish(sProduct._id, wList) && "hidden"
                      } w-5 h-5 md:w-6 md:h-6 cursor-pointer text-yellow-700`}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </div>
            </div>
            {sProduct.pCategory.priceConstant ? null : (
              <>
                {/* <div className="my-4 md:my-6 ">
                  <div className="text-lg text-gray-800 font-semibold">
                    Product Details:
                  </div>
                  <table>
                    <tr>
                      <td>Gold Weight:</td>{" "}
                      <td>{pDetails.weight ? pDetails.weight : ""}</td>
                    </tr>
                    <tr>
                      <td>Diamond Carat:</td> <td>{sProduct.DCarrett}</td>
                    </tr>
                    {sProduct.DOffer != 0 && (
                      <tr>
                        <td>Discount on Diamond:</td>{" "}
                        <td> {sProduct.DOffer}%</td>
                      </tr>
                    )}
                    <tr>
                      <td>Labour price per gram:</td>{" "}
                      <td>₹ {sProduct.LPrice}</td>
                    </tr>
                    {sProduct.LOffer != 0 && (
                      <tr>
                        <td>Discount on labour price per gram:</td>
                        {"  "}
                        <td> {sProduct.LOffer}%</td>
                      </tr>
                    )}
                  </table>
                </div> */}
                <div className="my-4 md:my-6">
                  <div className="text-lg text-gray-800 font-semibold">
                    Product Details:
                  </div>
                  <table className="mt-2 w-full text-sm md:text-base">
                    <tbody>
                      <tr className="bg-gray-100">
                        <td className="px-4 py-2">Gold Weight:</td>
                        <td className="px-4 py-2">
                          {pDetails.weight ? pDetails.weight : ""}
                        </td>
                      </tr>
                      <tr className="bg-gray-200">
                        <td className="px-4 py-2">Diamond Carat:</td>
                        <td className="px-4 py-2">
                          {sProduct.DCarrett}(Carat)
                        </td>
                      </tr>
                      {priceBreakUp.dMOffer !== 0 && (
                        <tr className="bg-gray-100">
                          <td className="px-4 py-2">Discount on Diamond:</td>
                          <td className="px-4 py-2">
                            {isNaN(priceBreakUp.dMOffer)
                              ? 0
                              : priceBreakUp.dMOffer}
                            %
                          </td>
                        </tr>
                      )}
                      <tr className="bg-gray-200">
                        <td className="px-4 py-2">Labour price per gram:</td>
                        <td className="px-4 py-2">
                          {convertToINR(
                            Math.ceil((sProduct.LPrice * 100) / 80)
                          )}
                        </td>
                      </tr>
                      {sProduct.LOffer !== 0 && (
                        <tr className="bg-gray-100">
                          <td className="px-4 py-2">
                            Discount on labour price per gram:
                          </td>
                          {/* <td className="px-4 py-2">{sProduct.LOffer}%</td> */}
                          <td className="px-4 py-2">{priceBreakUp.lMOffer}%</td>
                        </tr>
                      )}
                      {sProduct.pDiamension !== 0 && (
                        <tr className="bg-gray-200">
                          <td className="px-4 py-2">Dimension:</td>
                          <td className="px-4 py-2">{sProduct.pDiamension}</td>
                        </tr>
                      )}
                      {sProduct.extraStones && sProduct.extraStones.length ?
                        <tr>
                          <div className="text-base text-gray-800 font-semibold pt-3 py-2">
                            Extra Stones:
                          </div>
                        </tr>
                        : null}
                      {sProduct.extraStones && sProduct.extraStones.map((a, i) => (
                        a.replacable ?
                          (newOne && Object.keys(newOne).length > 0 ?
                            <>
                              <tr className={`bg-gray-${(i + 1) % 2 ? "2" : "1"}00`}>
                                <td className="px-4 py-2">{newOne["Stock Id"]} <span className="ml-1"><i onClick={()=>{setNewOne({})}} className="fa fa-times text-sm text-gray-500 cursor-pointer "></i></span></td>
                                <td className="px-4 py-2">{convertToINR(Math.ceil(newOne["Net Amt(Rs)"]))}</td>
                              </tr>
                              <tr className={`bg-gray-${(i + 1) % 2 ? "1" : "2"}00 line-through`}>
                                <td className="px-4 py-2">{a.stoneName} <span className="ml-2 text-sm text-red-500 font-semibold ">Replaced</span></td>
                                <td className="px-4 py-2">{convertToINR(Math.ceil(a.stonePrice))}</td>
                              </tr>
                            </> :
                            <tr className={`bg-gray-${(i + 1) % 2 ? "1" : "2"}00`}>
                              <td className="px-4 py-2">{a.stoneName} <span className="ml-2 text-sm text-blue-500 font-semibold cursor-pointer hover:text-blue-800" onClick={() => document.getElementById("centerStoneTable").scrollIntoView({ behavior: "smooth" })}>Replace</span></td>
                              <td className="px-4 py-2">{convertToINR(Math.ceil(a.stonePrice))}</td>
                            </tr>) :
                          <tr className={`bg-gray-${(i + 1) % 2 ? "1" : "2"}00`}>
                            <td className="px-4 py-2">{a.stoneName}</td>
                            <td className="px-4 py-2">{convertToINR(Math.ceil(a.stonePrice))}</td>
                          </tr>
                      ))}
                      {sProduct.custumizable && (!sProduct.extraStones || sProduct.extraStones.length === 0) && newOne && Object.keys(newOne).length > 0 && (
                        <tr className="bg-gray-200 fade-in">
                          <td className="px-4 py-2">{newOne["Stock Id"]} <span className="ml-1"><i onClick={()=>{setNewOne({})}} className="fa fa-times text-sm text-gray-500 cursor-pointer "></i></span></td>
                          <td className="px-4 py-2">{convertToINR(Math.ceil(newOne["Net Amt(Rs)"]))}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="my-4 md:my-6 text-gray-600">
                  {sProduct.pDescription}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="flex flex-col space-y-1">
                    <label htmlFor="selectgoldcarat">Gold Purity</label>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="selectgoldcarat"
                        value={pData.carrett}
                        onChange={(e) =>
                          setPData({ ...pData, carrett: e.target.value })
                        }
                      >
                        <MenuItem value="10">10KT</MenuItem>
                        <MenuItem value="14">14KT</MenuItem>
                        <MenuItem value="18">18KT</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="flex flex-col space-y-1">
                    <label htmlFor="selectdiamondvariant">
                      Diamond Quality
                    </label>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="selectdiamondvariant"
                        value={pData.diamond}
                        onChange={(e) =>
                          setPData({ ...pData, diamond: e.target.value })
                        }
                        className="uppercase"
                      >
                        {diamond.map((d, i) => (
                          <MenuItem key={i} value={d._id}>
                            {d.mName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div
                    className={`flex flex-col space-y-1 ${sProduct.pCategory.sizeRequired
                      ? "col-span-1"
                      : "col-span-2"
                      }`}
                  >
                    <label htmlFor="selectcolourvariant">
                      Select Colour Variant
                    </label>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="selectgoldcarat"
                        value={pData.color}
                        className="capitalize"
                        onChange={(e) => {
                          setPData({
                            ...pData,
                            color: e.target.value,
                          });
                          onSelectColor(
                            e.target.value,
                            sProduct.pImages,
                            setCount
                          );
                        }}
                      >
                        {colors.length > 0 &&
                          colors.map((col, i) => (
                            <MenuItem key={i} value={col._id}>
                              {col.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>

                  {sProduct.pCategory.sizeRequired ? (
                    <div
                      className="flex flex-col space-y-1"
                      style={{ marginTop: "-12px" }}
                    >
                      <label htmlFor="selectrsize">
                        Select Size{" "}
                        <Button
                          onClick={() => setOpenSizeGuide(true)}
                          variant="text"
                          className="normal-case"
                          size="xs"
                        >
                          Guide
                        </Button>
                      </label>
                      <FormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="selectrsize"
                          value={
                            pData.weight ? pData.weight : sProduct.Size[0]?._id
                          }
                          onChange={(e) =>
                            setPData({ ...pData, weight: e.target.value })
                          }
                        >
                          {sProduct.Size.map((p, i) => (
                            <MenuItem key={i} value={p._id}>
                              {p.size}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
            {sProduct.pCategory.priceConstant ? (
              <>
                <div className="my-4 md:my-6 text-gray-600">
                  {sProduct.pDescription}
                </div>
                {/* <div className={`flex flex-col space-y-1 w-full col-span-1`}>
                  <label htmlFor="selectcolourvariant">
                    Select Colour Variant
                  </label>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="selectgoldcarat"
                      value={pData.color}
                      className="capitalize"
                      onChange={(e) => {
                        setPData({
                          ...pData,
                          color: e.target.value,
                        });
                        onSelectColor(
                          e.target.value,
                          sProduct.pImages,
                          setCount
                        );
                      }}
                    >
                      {colors.length > 0 &&
                        colors.map((col, i) => (
                          <MenuItem key={i} value={col._id}>
                            {col.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div> */}
              </>
            ) : (
              <></>
            )}
            <div className="my-4 md:my-6">
              {quantitiy == sProduct.pQuantity ? (
                <span className="text-xs text-red-500">Stock limited</span>
              ) : (
                ""
              )}

              {/* Incart and out of stock button */}
              <div
                className={`hidden w-full md:grid grid-cols-${sProduct.pSubCategory.name === "Photo Pendants" &&
                  window.localStorage.getItem("jwt")
                  ? "2"
                  : "1"
                  }`}
              >
                {sProduct.pQuantity !== 0 ? (
                  <Fragment>
                    {/* <div className="text-xs md:text-sm text-center text-white bg-red-600 width-full">
                      Discount on Diamond and Labour Price* (Inclusive of Price)
                    </div> */}

                    {layoutData.inCart !== null &&
                      layoutData.inCart.includes(sProduct._id) === true ? (
                      // <div
                      //   style={{ background: "#303031" }}
                      //   className={`rounded px-4 py-2 text-white text-center cursor-not-allowed uppercase opacity-75 rounded`}
                      // >
                      //   Added to Cart
                      // </div>
                      <Button
                        variant="contained"
                        size="large"
                        startIcon={<ShoppingBasketIcon></ShoppingBasketIcon>}
                        onClick={(e) =>
                          addToCart(
                            sProduct._id,
                            sProduct.createdAt,
                            pData.carrett,
                            pData.weight,
                            pData.diamond,
                            pData.color,
                            layoutDispatch,
                            newOne,
                            fetchData
                          )
                        }
                        className={`px-4 py-2 w-full text-white text-center cursor-pointer uppercase`}
                      >
                        Add to cart
                      </Button>
                    ) : (
                      <>
                        {sProduct.pSubCategory.name === "Photo Pendants" &&
                          login && (
                            <Button
                              variant="contained"
                              size="large"
                              color="warning"
                              startIcon={<AutoFixHighIcon></AutoFixHighIcon>}
                              onClick={(e) => {
                                setPhotoPandent(true);
                              }}
                              className={`px-4 py-2 w-full text-white text-center cursor-pointer uppercase`}
                            >
                              Customize
                            </Button>
                          )}
                        <Button
                          variant="contained"
                          size="large"
                          startIcon={<ShoppingBasketIcon></ShoppingBasketIcon>}
                          onClick={(e) =>
                            addToCart(
                              sProduct._id,
                              sProduct.createdAt,
                              pData.carrett,
                              pData.weight,
                              pData.diamond,
                              pData.color,
                              layoutDispatch,
                              customStone,
                              fetchData
                            )
                          }
                          className={`px-4 py-2 w-full text-white text-center cursor-pointer uppercase`}
                        >
                          Add to cart
                        </Button>
                      </>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {layoutData.inCart !== null &&
                      layoutData.inCart.includes(sProduct._id) === true ? (
                      <div
                        style={{ background: "#303031" }}
                        className={`px-4 py-2 rounded text-white text-center cursor-not-allowed uppercase opacity-75`}
                      >
                        Added to Cart
                      </div>
                    ) : (
                      <div
                        style={{ background: "#303031" }}
                        disabled={true}
                        className="px-4 py-2 text-white opacity-50 cursor-not-allowed text-center uppercase"
                      >
                        Out of stock
                      </div>
                    )}
                  </Fragment>
                )}
              </div>
              {/* Incart and out of stock button End */}
            </div>
          </div>
        </div>
      </section>
      <div
        style={{ zIndex: "999" }}
        className={`fixed md:hidden bottom-0 w-full grid grid-cols-${sProduct.pSubCategory.name === "Photo Pendants" &&
          window.localStorage.getItem("jwt")
          ? "2"
          : "1"
          }`}
      >

        <>
          {sProduct.pSubCategory.name === "Photo Pendants" && login && (
            <Button
              variant="contained"
              size="large"
              color="warning"
              startIcon={<AutoFixHighIcon></AutoFixHighIcon>}
              onClick={(e) => {
                setPhotoPandent(true);
              }}
              className={`px-4 py-2 w-full text-white text-center cursor-pointer uppercase`}
            >
              Customize
            </Button>
          )}

          <Button
            variant="contained"
            size="large"
            startIcon={<ShoppingBasketIcon></ShoppingBasketIcon>}
            onClick={(e) =>
              addToCart(
                sProduct._id,
                sProduct.createdAt,
                pData.carrett,
                pData.weight,
                pData.diamond,
                pData.color,
                layoutDispatch,
                newOne,
                fetchData
              )
            }
            className={`px-4 py-4 w-full text-white text-center cursor-pointer uppercase`}
          >
            Add to cart
          </Button>
        </>

      </div>
      {sProduct.pCategory.priceConstant ? null : (
        <>
          {/* <PriceTab
            // gPrice={priceBreakUp.gPrice}
            gPrice={(
              Math.round((priceBreakUp.gPrice / priceBreakUp.Weight) * 100) /
              100
            ).toFixed(2)}
            weight={priceBreakUp.Weight}
            dQua={priceBreakUp.diamond?.mName}
            dPrice={priceBreakUp.diamond?.mPrice}
            carat={sProduct.DCarrett}
            offer={sProduct.DOffer}
            labour={sProduct.LPrice}
            doofer={sProduct.DOffer}
          /> */}
          {/* <section className="m-4 md:mx-12 md:my-6 md:px-5">
            <div className="font-bold text-lg ">Price Break Up</div>
            <div className="p-2 w-full overflow-auto">
              <table class="table-auto w-full space-y-2  text-sm md:text-base">
                <thead className="space-y-2">
                  <tr>
                    <th>Material</th>
                    <th>Per Gram/Carat Amount</th>
                    <th>Weight</th>
                    <th>Amount</th>
                    <th>Discount</th>
                    <th>Final Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="space-x-2">
                    <td>Gold</td>
                    <td>
                      {(
                        Math.round(
                          (priceBreakUp.gPrice / priceBreakUp.Weight) * 100
                        ) / 100
                      ).toFixed(2)}
                    </td>
                    <td>{priceBreakUp.Weight}</td>
                    <td>
                      {(Math.round(priceBreakUp.gPrice * 100) / 100).toFixed(2)}
                    </td>
                    <td>0</td>
                    <td>
                      {(Math.round(priceBreakUp.gPrice * 100) / 100).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="space-x-2">
                    <td>Diamond({priceBreakUp.diamond?.mName})</td>
                    <td>{priceBreakUp.diamond?.mPrice}</td>
                    <td>{sProduct.DCarrett}(Carat)</td>
                    <td>{priceBreakUp.dPrice}</td>
                    <td>{sProduct.DOffer}</td>
                    <td>
                      {(
                        Math.round(priceBreakUp.discounteddprice * 100) / 100
                      ).toFixed(2)}
                    </td>
                  </tr>

                  <tr className="space-x-2">
                    <td>Labour Price</td>
                    <td>{sProduct.LPrice}</td>
                    <td>{priceBreakUp.Weight}</td>
                    <td>
                      {(Math.round(priceBreakUp.lPrice * 100) / 100).toFixed(2)}
                    </td>
                    <td>{sProduct.LOffer}</td>
                    <td>
                      {(
                        Math.round(priceBreakUp.discounteddprice * 100) / 100
                      ).toFixed(2)}
                    </td>
                  </tr>

                  <tr className="space-x-2">
                    <td>Total Price</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{sProduct.pPrice}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section> */}
          <section className="m-4 md:mx-12 md:my-6 md:px-5">
            <div className="font-bold text-lg">Price Break Up</div>
            <div className="p-2 w-auto overflow-auto">
              <table className="table-auto  w-full text-sm md:text-base">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="px-4 py-2">Material</th>
                    {/* <th className="px-4 py-2">Per Gram/Carat Amount</th> */}
                    <th className="px-4 py-2">Weight</th>
                    <th className="px-4 py-2">Amount</th>
                    <th className="px-4 py-2">Discount</th>
                    <th className="px-4 py-2">Final Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-gray-200">
                    <td className="px-4 py-2 font-semibold">Gold</td>
                    {/* <td className="px-4 py-2"> */}
                    {/* {(
                        Math.round(
                          (priceBreakUp.gPrice / priceBreakUp.Weight) * 100
                        ) / 100
                      ).toFixed(2)} */}
                    {/* {convertToINR(Math.ceil(priceBreakUp.gPerGMPrice))}
                    </td> */}
                    <td className="px-4 py-2">{priceBreakUp.Weight} gms</td>
                    <td className="px-4 py-2">
                      {/* {(Math.round(priceBreakUp.gPrice * 100) / 100).toFixed(2)} */}
                      {convertToINR(Math.ceil(priceBreakUp.gMPrice))}
                      {console.log("first", priceBreakUp)}
                    </td>
                    <td className="px-4 py-2">20%</td>
                    <td className="px-4 py-2">
                      {/* {(Math.round(priceBreakUp.gPrice * 100) / 100).toFixed(2)} */}
                      {convertToINR(Math.ceil(priceBreakUp.gPrice))}

                      {/* <span className="line-through text-red-500 ml-2">
                      {((priceBreakUp.gPrice * 100) / 80).toLocaleString(
                        "en-IN",
                        {
                          maximumFractionDigits: 0,
                          style: "currency",
                          currency: "INR",
                        }
                      )}
                      </span> */}
                    </td>
                  </tr>
                  <tr className="bg-gray-300">
                    <td className="px-4 py-2 font-semibold">
                      Diamond({priceBreakUp.diamond?.mName})
                    </td>
                    {/* <td className="px-4 py-2">
                      {convertToINR(
                        Math.ceil(priceBreakUp.diamond?.mMPrice || 0)
                      )}
                    </td> */}
                    <td className="px-4 py-2">{sProduct.DCarrett}(Carat)</td>
                    <td className="px-4 py-2">
                      {convertToINR(Math.ceil(priceBreakUp.dMPrice))}
                    </td>
                    <td className="px-4 py-2">
                      {isNaN(priceBreakUp.dMOffer) ? 0 : priceBreakUp.dMOffer}%
                    </td>
                    <td className="px-4 py-2">
                      {/* {(
                        Math.round(priceBreakUp.discounteddprice * 100) / 100
                      ).toFixed(2)} */}
                      {convertToINR(
                        Math.ceil(priceBreakUp.discounteddprice || 0)
                      )}
                    </td>
                  </tr>
                  <tr className="bg-gray-200">
                    <td className="px-4 py-2 font-semibold">Labour Price</td>
                    {/* <td className="px-4 py-2">
                      {convertToINR(Math.ceil(priceBreakUp.lMGPrice))}
                    </td> */}
                    <td className="px-4 py-2">{priceBreakUp.Weight} gms</td>
                    <td className="px-4 py-2">
                      {/* {(Math.round(priceBreakUp.lPrice * 100) / 100).toFixed(2)} */}
                      {convertToINR(Math.ceil(priceBreakUp.lMPrice))}
                    </td>
                    <td className="px-4 py-2">{priceBreakUp.lMOffer}%</td>
                    <td className="px-4 py-2">
                      {/* {(
                        Math.round(priceBreakUp.discounteddprice * 100) / 100
                      ).toFixed(2)} */}
                      {convertToINR(
                        Math.ceil(priceBreakUp.discountedlprice || 0)
                      )}
                    </td>
                  </tr>


                  {sProduct.extraStones && sProduct.extraStones.map((a, i) => (
                    a.replacable ?
                      (newOne && Object.keys(newOne).length > 0 ?
                        <>
                          <tr className={`bg-gray-${(i + 1) % 2 ? "3" : "2"}00`}>
                            <td className="px-4 py-2">{newOne["Stock Id"]} <span className="ml-1"><i onClick={()=>{setNewOne({})}} className="fa fa-times text-sm text-gray-500 cursor-pointer "></i></span></td>
                            <td className="px-4 py-2"></td>
                            <td className="px-4 py-2"></td>
                            <td className="px-4 py-2"></td>
                            <td className="px-4 py-2">{convertToINR(Math.ceil(newOne["Net Amt(Rs)"]))}</td>
                          </tr>
                          <tr className={`bg-gray-${(i + 1) % 2 ? "2" : "3"}00 line-through`}>
                            <td className="px-4 py-2">{a.stoneName} <span className="ml-2 text-sm text-red-500 font-semibold ">Replaced</span></td>
                            <td className="px-4 py-2"></td>
                            <td className="px-4 py-2"></td>
                            <td className="px-4 py-2"></td>
                            <td className="px-4 py-2">{convertToINR(Math.ceil(a.stonePrice))}</td>
                          </tr>
                        </> :
                        <tr className={`bg-gray-${(i + 1) % 2 ? "2" : "3"}00`}>
                          <td className="px-4 py-2">{a.stoneName} <span className="ml-2 text-sm text-blue-500 font-semibold cursor-pointer hover:text-blue-800" onClick={() => document.getElementById("centerStoneTable").scrollIntoView({ behavior: "smooth" })}>Replace</span></td>
                          <td className="px-4 py-2"></td>
                          <td className="px-4 py-2"></td>
                          <td className="px-4 py-2"></td>
                          <td className="px-4 py-2">{convertToINR(Math.ceil(a.stonePrice))}</td>
                        </tr>) :
                      <tr className={`bg-gray-${(i + 1) % 2 ? "2" : "3"}00`}>
                        <td className="px-4 py-2">{a.stoneName}</td>
                        <td className="px-4 py-2"></td>
                        <td className="px-4 py-2"></td>
                        <td className="px-4 py-2"></td>
                        <td className="px-4 py-2">{convertToINR(Math.ceil(a.stonePrice))}</td>
                      </tr>
                  ))}
                  {sProduct.custumizable && (!sProduct.extraStones || sProduct.extraStones.length === 0) && newOne && Object.keys(newOne).length > 0 && (
                    <tr className="bg-gray-200 fade-in">
                      <td className="px-4 py-2">{newOne["Stock Id"]} <span className="ml-1"><i onClick={()=>{setNewOne({})}} className="fa fa-times text-sm text-gray-500 cursor-pointer "></i></span></td>
                      <td className="px-4 py-2"></td>
                      <td className="px-4 py-2"></td>
                      <td className="px-4 py-2"></td>
                      <td className="px-4 py-2">{convertToINR(Math.ceil(newOne["Net Amt(Rs)"]))}</td>
                    </tr>
                  )}
                  <tr className="bg-gray-300">
                    <td className="px-4 py-2 font-bold text-lg text-gray-700">
                      Total Price
                    </td>
                    {/* <td className="px-4 py-2"></td> */}
                    <td className="px-4 py-2"></td>
                    <td className="px-4 py-2"></td>
                    <td className="px-4 py-2"></td>
                    <td className="px-4 py-2">
                      {convertToINR(
                        Math.ceil(calculatePrice(sProduct.pPrice))
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          {sProduct.custumizable && (
            <section className="m-4 md:mx-12 md:my-6 md:px-5">
              <CustomData
                customStone={customStone}
                setCustomStone={setCustomStone}
                setNewOne={setNewOne}
                newOne={newOne}
              ></CustomData>
            </section>
          )}
        </>
      )}
      {/* Product Details Section two */}
      <section className="m-4 md:mx-12 md:my-8">
        <ProductDetailsSectionTwo fetchData={fetchData} />

        <RelatedProducts
          pPrice={sProduct.pPrice}
          pSubCatagory={sProduct.pSubCategory._id}
        ></RelatedProducts>
      </section>
    </Fragment>
  );
};

export default React.memo(ProductDetailsSection);
