import React, { Fragment, useState, useContext } from "react";
import { newPassword } from "./fetchApi";
import { LayoutContext } from "../index";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
const ResetPassword = () => {
  const { data: layoutData, dispatch: layoutDispatch } =
    useContext(LayoutContext);
const history = useNavigate();
  const [data, setData] = useState({
    password: "",
    cpassword: "",
    error: false,
    loading: true,
  });
  const { token } = useParams();
  const alert = (msg) => <div className="text-xs text-red-500">{msg}</div>;

  const formSubmit = async () => {
    setData({ ...data, loading: true });
    try {
      let responseData = await newPassword({
        password: data.password,
        cPassword: data.cpassword,
        token,
      });
      if (responseData.error) {
        setData({
          ...data,
          loading: false,
          error: responseData.error,
          password: "",
          cpassword: "",
        });
        toast.error(responseData.error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      } else if (responseData.message) {
        setData({ password: "", cpassword: "", loading: false, error: false });
        toast.success(responseData.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
        setTimeout(()=>{
          history('/')
        }, 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Fragment>
      <div className=" text-center text-2xl mt-3 mb-6 ">Reset Password</div>

      <div className="flex flex-col items-center justify-center mt-10 w-full ">
        <div className="flex flex-col w-2/4">
          <label htmlFor="password">
            New Password
            <span className="text-sm text-gray-600 ml-1">*</span>
          </label>
          <input
            onChange={(e) => {
              setData({ ...data, password: e.target.value, error: false });
              layoutDispatch({ type: "loginSignupError", payload: false });
            }}
            value={data.password}
            type="text"
            id="name"
            className={`${
              !data.error ? "" : "border-red-500"
            } px-4 py-2 focus:outline-none border`}
          />
          {!data.error ? "" : alert(data.error)}

          <label htmlFor="cpassword">
            Confirm Password
            <span className="text-sm text-gray-600 ml-1">*</span>
          </label>
          <input
            onChange={(e) => {
              setData({ ...data, cpassword: e.target.value, error: false });
              layoutDispatch({ type: "loginSignupError", payload: false });
            }}
            value={data.cpassword}
            type="text"
            id="name"
            className={`${
              !data.error ? "" : "border-red-500"
            } px-4 py-2 focus:outline-none border`}
          />
          {!data.error ? "" : alert(data.error)}
        </div>
        <div
          onClick={(e) => {formSubmit();navigator.vibrate(30)}}
          style={{ background: "#303031" }}
          className="font-medium px-4 py-2 text-white text-center cursor-pointer w-2/4 border mt-3"
        >
          Update Password
        </div>
      </div>
    </Fragment>
  );
};

export default ResetPassword;
