import React, { Fragment, useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { getContents } from "../../admin/content/FetchApi";
const productHeilights = [
  {
    name: "Shop",
    url: "/shop",
  },
  {
    name: "Customised Rings",
    url: "/customize-ring",
  },
  {
    name: "Diamond Watches",
    url: "/shop/diamond-watches",
  },
  {
    name: "Suggest your own",
    url: "/suggest-your-own-jwellery",
  }
];
const Footer = (props) => {
  const [contents, setContents] = useState([]);

  const fetchContents = async () => {
    let response = await getContents();
    console.log(response);
    setContents(response);
  };
  useEffect(() => {
    fetchContents();
  }, []);
  return (
    <Fragment>
      <div
        style={{ background: "rgb(84 84 86)", color: "rgb(215 217 217)" }}
        className="grid grid-cols-1 md:grid-cols-5  pt-4  footerStyle"
      >
        <div className="flex flex-col space-y-2 items-center ">
          <ul className="p-0">
            <li className="font-bold uppercase text-base md:text-lg">
              Product Highlight
            </li>
            {productHeilights.map((fc) => (
              <li className="capitalize">
                <Link
                  className="no-underline text-gray-500 md:text-base text-sm hover:text-gray-200"
                  to={`${fc.url}`}
                >
                  {fc.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex flex-col space-y-2 items-center ">
          <ul className="p-0">
            <li className="font-bold uppercase text-base md:text-lg">GUIDE</li>
            {contents &&
              contents
                .filter((c) => c.content_type === "guide")
                .map((fc) => (
                  <li className="capitalize">
                    <Link
                      className="no-underline text-gray-500 md:text-base text-sm hover:text-gray-200"
                      to={`/contents/${fc.url}`}
                    >
                      {fc.name}
                    </Link>
                  </li>
                ))}
          </ul>
        </div>
        <div className="flex flex-col space-y-2 items-center">
          <ul className="p-0">
            <li className="font-bold uppercase text-base md:text-lg">
              SERVICES
            </li>
            {contents &&
              contents
                .filter((c) => c.content_type === "services")
                .map((fc) => (
                  <li className="capitalize">
                    <Link
                      className="no-underline text-gray-500 md:text-base text-sm hover:text-gray-200"
                      to={`/contents/${fc.url}`}
                    >
                      {fc.name}
                    </Link>
                  </li>
                ))}
          </ul>
        </div>
        <div className="flex flex-col space-y-2 items-center">
          <ul className="p-0">
            <li className="font-bold uppercase text-base md:text-lg">
              Consumer Confidence
            </li>
            {contents &&
              contents
                .filter((c) => c.content_type === "consumer-confidence")
                .map((fc) => (
                  <li className="capitalize">
                    <Link
                      className="no-underline text-gray-500 md:text-base text-sm hover:text-gray-200"
                      to={`/contents/${fc.url}`}
                    >
                      {fc.name}
                    </Link>
                  </li>
                ))}
          </ul>
        </div>
        {/* <div className="flex flex-col space-y-2 items-center">
          <ul className="p-0">
            <li className="font-bold uppercase text-base md:text-lg">QUERY</li>
            {contents && contents
              .filter((c) => c.content_type === "query")
              .map((fc) => (
                <li className="capitalize">
                  <Link
                    className="no-underline text-gray-500 md:text-base text-sm hover:text-gray-200"
                    to={`/contents/${fc.url}`}
                  >
                    {fc.name}
                  </Link>
                </li>
              ))}
          </ul>
        </div> */}
        <div className="flex flex-col space-y-2 items-center">
          <ul className="p-0">
            <li className="font-bold uppercase text-base md:text-lg">
              CONTACT US
            </li>
            <li className="flex justify-center md:justify-start flex-row space-x-4 text-xl md:text-2xl mt-2">
              <a target="_blank" href="https://Facebook.com/HMDesignsInc/">
                {" "}
                <i class="fab fa-facebook text-gray-400 hover:text-gray-100 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"></i>
              </a>{" "}
              <a target="_blank" href="https://instagram.com/HMDesignsInc/">
                <i class="fab fa-instagram text-gray-400 hover:text-gray-100 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"></i>
              </a>{" "}
              <a target="_blank" href="https://wa.me/message/WWVUN5G3GFEKF1">
                <i class="fab fa-whatsapp text-gray-400 hover:text-gray-100 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"></i>
              </a>
            </li>
            <li className="text-gray-500 md:text-base text-sm hover:text-gray-200">+91-9833287775 (HELPLINE);</li>
           
            <li className="text-gray-500 md:text-base text-sm hover:text-gray-200">Working Hours – 9am –
              7pm IST (Mon-Sat)</li>
            <li className="text-gray-500 md:text-base text-sm hover:text-gray-200">info@hnmdesigns.com</li>
            <li className="text-gray-500 md:text-base text-sm hover:text-gray-200">Corporate Enquiries -</li>
            <li className="text-gray-500 md:text-base text-sm hover:text-gray-200">info@hnmdesigns.com</li>
            <li className="text-gray-500 md:text-base text-sm hover:text-gray-200">Email Newsletters</li>
            <li className="text-gray-500 md:text-base text-sm hover:text-gray-200"> Address:-</li>
            <li className="text-gray-500 md:text-base text-sm hover:text-gray-200">
             
              JE1230 BDB, BKC,
Mumbai 400051</li>
          </ul>
        </div>
      </div>
      <footer
        style={{ background: "#303031", color: "#87898A" }}
        className={`z-10 py-4 md:py-6 px-2 md:px-4 md:px-12 text-center  text-xs md:text-sm`}
      >
        H&M Designs © Copyright {moment().format("YYYY")} {` `}
        {contents &&
          contents
            .filter((c) => c.content_type === "terms")
            .map((fc) => (
              <Link to={`/contents/${fc.url}`} className="no-underline">
                Terms of Use
              </Link>
            ))}
        {` | `}
        {contents &&
          contents
            .filter((c) => c.content_type === "privacy")
            .map((fc) => (
              <Link to={`/contents/${fc.url}`} className="no-underline">
                Privacy Policy
              </Link>
            ))}
      </footer>
    </Fragment>
  );
};

export default Footer;
