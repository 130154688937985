import React, { Fragment, useContext, useState, useEffect } from "react";
import { ProductContext } from "./index";
import { toast } from "react-toastify";
import { createProduct, getAllProduct } from "./FetchApi";
import { getAllCategory, fetchSubCatagories } from "../categories/FetchApi";
import AddSideWeight from "./AddSideWeight";
import ExtraStone from "./ExtraStone";
const AddProductDetail = ({ categories }) => {
  const { data, dispatch } = useContext(ProductContext);

  const alert = (msg, type) => (
    <div className={`bg-${type}-200 py-2 px-4 w-full`}>{msg}</div>
  );
  const [sizeRequired, setSizeRequired] = useState(true);
  const [priceConstant, setPriceConstant] = useState(false);
  const [isSaving, setSaving] = useState(0);
  const [fData, setFdata] = useState({
    pName: "",
    modelNumber: "",
    pDescription: "",
    pStatus: true,
    pCategory: "",
    WeightAndSize: [],
    extraStones:[],
    extraStoneAdded: false,
    pSubCatagory: "",
    custumizable: false,
    sex: "unisex",
    goldWeight: 0,
    pPrice: 0,
    sName: "",
    sPrice: 0,
    DCarrett: 0,
    DOffer: 0,
    LPrice: 0,
    LOffer: 0,
    pDiamension: "",
    success: false,
    error: false,
  });
  const [subCatagories, setSubCatagories] = useState([]);

  const getSubCatagories = async (cat) => {
    let subCat = await fetchSubCatagories(cat);
    if (subCat) {
      setSubCatagories(subCat);
    }
  };
  const sizereq = (value) => {
    categories.filter((cat) => {
      if (cat._id === value) {
        setSizeRequired(cat.sizeRequired);
        setPriceConstant(cat.priceConstant);
        console.log(sizeRequired);
      }
    });
  };
  const WeightAndSize = (sizeArr) => {
    setFdata({
      ...fData,
      error: false,
      success: false,
      WeightAndSize: sizeArr,
    });
  };

  const extraStones = (stoneArr) => {
    setFdata({
      ...fData,
      error: false,
      success: false,
      extraStones: stoneArr,
    });
  };

  const fetchData = async () => {
    let responseData = await getAllProduct();
    setTimeout(() => {
      if (responseData && responseData.Products) {
        dispatch({
          type: "fetchProductsAndChangeState",
          payload: responseData.Products,
        });
      }
    }, 1000);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    e.target.reset();
    setSaving(1);
    try {
      console.log(fData);
      let responseData = await createProduct(fData);
      if (responseData.success) {
        fetchData();
        setSaving(2);
        setFdata({
          ...fData,
          pName: "",
          modelNumber: "",
          pDescription: "",
          pStatus: true,
          pCategory: "",
          WeightAndSize: [],
          extraStones:[],
          extraStoneAdded: false,
          custumizable: false,
          sex: "",
          pDiamension: "",
          pPrice: 0,
          sName: "",
          pSubCategory: "",
          goldWeight: 0,
          sPrice: 0,
          DCarrett: 0,
          DOffer: 0,
          LPrice: "",
          LOffer: "",
          success: responseData.success,
          error: false,
        });
        toast.success(responseData.success, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setTimeout(() => {
          setSaving(0);
          setFdata({
            ...fData,
            pName: "",
            modelNumber: "",
            pDescription: "",
            pStatus: true,
            pCategory: "",
            WeightAndSize: [],
            extraStones:[],
            extraStoneAdded: false,
            custumizable: false,
            sex: "",
            pPrice: 0,
            pDiamension: "",
            sName: "",
            pSubCatagory: "",
            sPrice: 0,
            DCarrett: 0,
            goldWeight: 0,
            DOffer: 0,
            LPrice: "",
            LOffer: "",
            success: false,
            error: false,
          });
        }, 2000);
      } else if (responseData.error) {
        toast.error(responseData.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setSaving(3);
        setFdata({ ...fData, success: false, error: responseData.error });
        setTimeout(() => {
          setSaving(0);
          return setFdata({ ...fData, error: false, success: false });
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      {/* Black Overlay */}
      <div
        onClick={(e) => dispatch({ type: "addProductModal", payload: false })}
        className={`${
          data.addProductModal ? "" : "hidden"
        } fixed top-0 left-0 z-30 w-full h-full bg-black opacity-50`}
      />
      {/* End Black Overlay */}

      {/* Modal Start */}
      <div
        className={`${
          data.addProductModal ? "" : "hidden"
        } top-0 absolute w-screen flex items-center z-30 justify-center left-0`}
      >
        <div className="mt-32 md:mt-0 relative bg-white w-11/12 md:w-3/6 shadow-lg flex flex-col items-center space-y-4 px-4 py-4 md:px-8 text-sm h-screen overflow-y-scroll">
          <div className="flex items-center justify-between w-full pt-4">
            <span className="text-left font-semibold text-2xl tracking-wider">
              Add Product
            </span>
            {/* Close Modal */}
            <span
              style={{ background: "#303031" }}
              onClick={(e) =>
                dispatch({ type: "addProductModal", payload: false })
              }
              className="cursor-pointer text-gray-100 py-2 px-2 rounded-full"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </div>
          {fData.error ? alert(fData.error, "red") : ""}
          {fData.success ? alert(fData.success, "green") : ""}
          <form className="w-full" onSubmit={(e) => submitForm(e)}>
            <div className="flex space-x-1 py-4">
              <div className="w-1/2 flex flex-col space-y-1 space-x-1">
                <label htmlFor="name">Product Name *</label>
                <input
                  value={fData.pName}
                  onChange={(e) =>
                    setFdata({
                      ...fData,
                      error: false,
                      success: false,
                      pName: e.target.value,
                    })
                  }
                  className="px-4 py-2 border focus:outline-none"
                  type="text"
                />
              </div>
              <div className="w-1/2 flex flex-col space-y-1 space-x-1">
                <label htmlFor="name">Model Number *</label>
                <input
                  value={fData.modelNumber}
                  onChange={(e) =>
                    setFdata({
                      ...fData,
                      error: false,
                      success: false,
                      modelNumber: e.target.value,
                    })
                  }
                  className="px-4 py-2 border focus:outline-none"
                  type="text"
                />
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <label htmlFor="description">Product Description *</label>
              <textarea
                value={fData.pDescription}
                onChange={(e) =>
                  setFdata({
                    ...fData,
                    error: false,
                    success: false,
                    pDescription: e.target.value,
                  })
                }
                className="px-4 py-2 border focus:outline-none"
                name="description"
                id="description"
                cols={5}
                rows={2}
              />
            </div>
            <div className="flex flex-row  w-full mt-3">
              <label htmlFor="sex1">Sex ?</label>
              <div className="flex items-center mr-4 ml-4 mb-4">
                <input
                  id="sex1"
                  type="radio"
                  name="sex1"
                  onClick={(e) =>
                    setFdata({
                      ...fData,
                      success: false,
                      error: false,
                      sex: "female",
                    })
                  }
                  checked={fData.sex === "female"}
                />
                <label
                  for="radio1"
                  className="flex items-center cursor-pointer"
                >
                  Female
                </label>
              </div>

              <div className="flex items-center mr-4 mb-4">
                <input
                  id="sex2"
                  type="radio"
                  name="sex1"
                  onClick={(e) =>
                    setFdata({
                      ...fData,
                      success: false,
                      error: false,
                      sex: "male",
                    })
                  }
                  checked={fData.sex === "male"}
                />
                <label
                  for="radio2"
                  className="flex items-center cursor-pointer"
                >
                  Male
                </label>
              </div>
              <div className="flex items-center mr-4 ml-4 mb-4">
                <input
                  id="sex3"
                  type="radio"
                  name="sex1"
                  onClick={(e) =>
                    setFdata({
                      ...fData,
                      success: false,
                      error: false,
                      sex: "unisex",
                    })
                  }
                  checked={fData.sex === "unisex"}
                />
                <label
                  for="radio1"
                  className="flex items-center cursor-pointer"
                >
                  Unisex
                </label>
              </div>
            </div>
            {/* Most Important part for uploading multiple image */}
            <div className="flex space-x-1 py-4">
              <div className="w-1/2 flex flex-col space-y-1">
                <label htmlFor="status">Product Status *</label>
                <select
                  value={fData.pStatus}
                  onChange={(e) =>
                    setFdata({
                      ...fData,
                      error: false,
                      success: false,
                      pStatus: e.target.value,
                    })
                  }
                  name="status"
                  className="px-4 py-2 border focus:outline-none"
                  id="status"
                >
                  <option name="status" value={true}>
                    Active
                  </option>
                  <option name="status" value={false}>
                    Disabled
                  </option>
                </select>
              </div>

              <div className="w-1/2 flex flex-col space-y-1">
                <label htmlFor="status">Product Category *</label>
                <select
                  value={fData.pCategory}
                  onChange={(e) => {
                    setFdata({
                      ...fData,
                      error: false,
                      success: false,
                      pCategory: e.target.value,
                    });
                    sizereq(e.target.value);
                    getSubCatagories(e.target.value);
                  }}
                  name="status"
                  className="px-4 py-2 border focus:outline-none"
                  id="status"
                >
                  <option disabled value="">
                    Select a category
                  </option>
                  {categories.length > 0
                    ? categories.map(function (elem) {
                        return (
                          <option value={elem._id} key={elem._id}>
                            {elem.cName}
                          </option>
                        );
                      })
                    : ""}
                </select>
              </div>
              <div className="w-1/2 flex flex-col space-y-1">
                <label htmlFor="status">Product Sub Category *</label>
                <select
                  value={fData.pSubCategory}
                  onChange={(e) => {
                    setFdata({
                      ...fData,
                      error: false,
                      success: false,
                      pSubCategory: e.target.value,
                    });
                    sizereq(e.target.value);
                  }}
                  name="status"
                  className="px-4 py-2 border focus:outline-none"
                  id="status"
                >
                  <option value="">Select a sub category</option>
                  {subCatagories.length > 0
                    ? subCatagories.map(function (elem) {
                        return (
                          <option value={elem._id} key={elem._id}>
                            {elem.name}
                          </option>
                        );
                      })
                    : ""}
                </select>
              </div>
            </div>
            <div
              className={`grid grid-cols-${priceConstant ? 3 : 2} space-x-2`}
            >
              {sizeRequired ? (
                <AddSideWeight
                  WeightAndSize={WeightAndSize}
                  Size={fData.WeightAndSize}
                ></AddSideWeight>
              ) : (
                <>
                  <div className="flex flex-col">
                    <label htmlFor="goldWeight">Gold Weight *</label>
                    <input
                      value={fData.goldWeight}
                      onChange={(e) =>
                        setFdata({
                          ...fData,
                          error: false,
                          success: false,
                          goldWeight: e.target.value,
                        })
                      }
                      type="number"
                      className="px-4 py-2 border focus:outline-none"
                      id="goldWeight"
                    />
                  </div>
                  {priceConstant ? (
                    <div className="flex flex-col">
                      <label htmlFor="pPrice">Price</label>
                      <input
                        value={fData.pPrice}
                        onChange={(e) =>
                          setFdata({
                            ...fData,
                            error: false,
                            success: false,
                            pPrice: e.target.value,
                          })
                        }
                        type="number"
                        className="px-4 py-2 border focus:outline-none"
                        id="pPrice"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
              <div className="flex flex-col ">
                <label>Diamension</label>
                <input
                  value={fData.pDiamension}
                  onChange={(e) =>
                    setFdata({
                      ...fData,
                      error: false,
                      success: false,
                      pDiamension: e.target.value,
                    })
                  }
                  className="px-2 py-1 border focus:outline-none"
                  type="text"
                />
              </div>
            </div>
            {fData.pCategory === "6150c537ea2f237c3e0fa244" && (
              <div className="flex flex-row  w-full mt-2">
                <label htmlFor="name">Cutomizable?</label>
                <div className="flex items-center mr-4 ml-4 mb-4">
                  <input
                    id="customtrue"
                    type="radio"
                    name="customtrue"
                    value="true"
                    onClick={(e) =>
                      setFdata({
                        ...fData,
                        success: false,
                        error: false,
                        custumizable: true,
                      })
                    }
                    checked={fData.custumizable === true}
                  />
                  <label
                    for="customtrue"
                    className="flex items-center cursor-pointer"
                  >
                    Yes
                  </label>
                </div>

                <div className="flex items-center mr-4 mb-4">
                  <input
                    id="customfalse"
                    type="radio"
                    name="customfalse"
                    value="false"
                    onClick={(e) =>
                      setFdata({
                        ...fData,
                        success: false,
                        error: false,
                        custumizable: false,
                      })
                    }
                    checked={fData.custumizable === false}
                  />
                  <label
                    for="customfalse"
                    className="flex items-center cursor-pointer"
                  >
                    No
                  </label>
                </div>
              </div>
            )}
            <div className="flex flex-row  w-full mt-2">
              <label htmlFor="name">Extra Stone Added?</label>
              <div className="flex items-center mr-4 ml-4 mb-4">
                <input
                  id="radio1"
                  type="radio"
                  name="radio"
                  value="true"
                  onClick={(e) =>
                    setFdata({
                      ...fData,
                      success: false,
                      error: false,
                      extraStoneAdded: true,
                    })
                  }
                  checked={fData.extraStoneAdded === true}
                />
                <label
                  for="radio1"
                  className="flex items-center cursor-pointer"
                >
                  Yes
                </label>
              </div>

              <div className="flex items-center mr-4 mb-4">
                <input
                  id="radio2"
                  type="radio"
                  name="radio"
                  value="false"
                  onClick={(e) =>
                    setFdata({
                      ...fData,
                      success: false,
                      error: false,
                      extraStoneAdded: false,
                    })
                  }
                  checked={fData.extraStoneAdded === false}
                />
                <label
                  for="radio2"
                  className="flex items-center cursor-pointer"
                >
                  No
                </label>
              </div>
            </div>
            {fData.extraStoneAdded ? (
              <ExtraStone
              extraStones={extraStones}
              Stones={fData.extraStones}
              custumizable={fData.custumizable}
              ></ExtraStone>
              // <div className="flex space-x-1 ">
              //   <div className="w-1/2 flex flex-col space-y-1">
              //     <label htmlFor="stone">Stone Name *</label>
              //     <input
              //       value={fData.SName}
              //       onChange={(e) =>
              //         setFdata({
              //           ...fData,
              //           error: false,
              //           success: false,
              //           SName: e.target.value,
              //         })
              //       }
              //       type="text"
              //       className="px-4 py-2 border focus:outline-none"
              //       id="stone"
              //     />
              //   </div>
              //   <div className="w-1/2 flex flex-col space-y-1">
              //     <label htmlFor="StoneP">Price</label>
              //     <input
              //       value={fData.Sprice}
              //       onChange={(e) =>
              //         setFdata({
              //           ...fData,
              //           error: false,
              //           success: false,
              //           Sprice: e.target.value,
              //         })
              //       }
              //       type="number"
              //       className="px-4 py-2 border focus:outline-none"
              //     />
              //   </div>
              // </div>
            ) : (
              ""
            )}
            <div className="flex space-x-1 ">
              <div className="w-1/2 flex flex-col space-y-1">
                <label htmlFor="Diamond">Diamond Carrett*</label>
                <input
                  value={fData.DCarrett}
                  onChange={(e) =>
                    setFdata({
                      ...fData,
                      error: false,
                      success: false,
                      DCarrett: e.target.value,
                    })
                  }
                  type="number"
                  className="px-4 py-2 border focus:outline-none"
                  id="Diamond"
                />
              </div>
              <div className="w-1/2 flex flex-col space-y-1">
                <label htmlFor="Doffer">Offer on Diamond (%) *</label>
                <input
                  value={fData.DOffer}
                  onChange={(e) =>
                    setFdata({
                      ...fData,
                      error: false,
                      success: false,
                      DOffer: e.target.value,
                    })
                  }
                  type="number"
                  className="px-4 py-2 border focus:outline-none"
                  id="Doffer"
                />
              </div>
            </div>
            <div className="flex space-x-1 py-4">
              <div className="w-1/2 flex flex-col space-y-1">
                <label htmlFor="LPrice">Labour Price*</label>
                <input
                  value={fData.LPrice}
                  onChange={(e) =>
                    setFdata({
                      ...fData,
                      error: false,
                      success: false,
                      LPrice: e.target.value,
                    })
                  }
                  type="number"
                  className="px-4 py-2 border focus:outline-none"
                  id="LPrice"
                />
              </div>
              <div className="w-1/2 flex flex-col space-y-1">
                <label htmlFor="LOffer">Offer on Labour Price (%) *</label>
                <input
                  value={fData.LOffer}
                  onChange={(e) =>
                    setFdata({
                      ...fData,
                      error: false,
                      success: false,
                      LOffer: e.target.value,
                    })
                  }
                  type="number"
                  className="px-4 py-2 border focus:outline-none"
                  id="LOffer"
                />
              </div>
            </div>
            <div className="flex flex-col space-y-1 w-full pb-4 md:pb-6 mt-4">
              <button
                style={{ background: "#303031" }}
                type="submit"
                className="rounded-full bg-gray-800 text-gray-100 text-lg font-medium py-2"
                disabled={isSaving == 0 ? false : true}
              >
                {isSaving == 0 && "Create product"}{" "}
                {isSaving == 1 && "Creating Product"}
                {isSaving == 2 && "Created Product"}
                {isSaving == 3 && "Failed to Create"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

const AddProductModal = (props) => {
  useEffect(() => {
    fetchCategoryData();
  }, []);

  const [allCat, setAllCat] = useState({});

  const fetchCategoryData = async () => {
    let responseData = await getAllCategory();
    if (responseData.Categories) {
      setAllCat(responseData.Categories);
    }
  };

  return (
    <Fragment>
      <AddProductDetail categories={allCat} />
    </Fragment>
  );
};

export default AddProductModal;
