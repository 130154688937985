import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, CircularProgress, Paper, Typography } from "@mui/material";
import { LayoutContext } from "../layout";
import Payment from "./payOptions";
import PayOnline from "./payment";
import { cartListProduct } from "../partials/FetchApi";
import { createOrder } from "./FetchApi";
import { fetchData } from "./Action";
import CouponComponent from "./Coupon";
import { ArrowLeft, ShoppingCart } from "lucide-react";

export const CheckoutComponent = (props) => {
  const navigate = useNavigate();
  const { data, dispatch } = useContext(LayoutContext);
  const [razorpayData, setRazorpayData] = useState({});
  const [paymentStatus, setPaymentStatus] = useState("unpaid");
  const [paymentMode, setPaymentMode] = useState(1);
  const [couponData, setCouponData] = useState({});
  const [disablePlaceOrder, setDisablePlaceOrder] = useState(false);

  const payOnline = async () => {
    setDisablePlaceOrder(true);
    let response = await createOrder(paymentMode);
    props.setOrderId(response.order_id);
    if (paymentMode === 1) {
      setRazorpayData(response);
    } else if (paymentMode === 0) {
      props.setCheckoutPage(2);
    }
  };

  useEffect(() => {
    fetchData(cartListProduct, dispatch);
  }, [dispatch]);

  if (data.loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <Button
        onClick={() => navigate(-1)}
        startIcon={<ArrowLeft />}
        className="mb-6 text-blue-600 hover:text-blue-800"
      >
        Back
      </Button>

      <Typography variant="h4" component="h1" className="mb-6 font-bold">
        Checkout
      </Typography>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <Paper elevation={3} className="p-6 rounded-lg">
          <Typography variant="h5" component="h2" className="mb-4 font-semibold">
            Order Summary
          </Typography>
          <CheckoutProducts products={data.cartProduct} />
          <div className="mt-6 pt-4 border-t border-gray-200 flex justify-between items-center">
            <Typography variant="h6">Grand Total:</Typography>
            <Typography variant="h5" className="font-bold text-green-700">
              {data?.cartProduct
                .reduce((a, c) => a + c.pPrice, 0)
                .toLocaleString("en-IN", {
                  style: "currency",
                  currency: "INR",
                })}
            </Typography>
          </div>
        </Paper>

        <Paper elevation={3} className="p-6 rounded-lg">
          <Typography variant="h5" component="h2" className="mb-4 font-semibold">
            Payment Details
          </Typography>
          <div className="mb-6">
            <Payment
              paymentMode={paymentMode}
              setpaymentMode={setPaymentMode}
            />
          </div>
          <CouponComponent
            setCouponData={setCouponData}
            couponData={couponData}
          />
          <div className="mt-6">
            <PayOnline
              razorpayData={razorpayData}
              paymentStatus={paymentStatus}
              paymentMode={paymentMode}
              setCheckoutPage={props.setCheckoutPage}
              setPaymentStatus={setPaymentStatus}
            />
            <div className="grid grid-cols-2 gap-4 mt-6">
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={payOnline}
                disabled={disablePlaceOrder}
                startIcon={<ShoppingCart />}
                className="bg-green-600 hover:bg-green-700"
              >
                Place Order
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                onClick={() => navigate("/shop")}
                className="border-blue-600 text-blue-600 hover:bg-blue-50"
              >
                Continue Shopping
              </Button>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

const CheckoutProducts = ({ products }) => {
  return (
    <div className="space-y-4">
      {products && products.length > 0 ? (
        products.map((product, index) => (
          <Paper key={index} elevation={2} className="p-4 flex items-center space-x-4">
            <img
              className="h-20 w-20 object-cover rounded-md"
              src={`${product.pImages[0]?.url}`}
              alt={product.pName}
            />
            <div className="flex-grow">
              <Typography variant="subtitle1" className="font-semibold">
                {product.pName}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Amount:{" "}
                {product.pPrice.toLocaleString("en-IN", {
                  style: "currency",
                  currency: "INR",
                })}
              </Typography>
            </div>
          </Paper>
        ))
      ) : (
        <Typography color="textSecondary">No products found for checkout</Typography>
      )}
    </div>
  );
};

export default CheckoutComponent;