import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { searchProduct } from "./FetchApi";
import { useRef } from "react";
import { useEffect } from "react";

export default function Search(props) {
  const [data, setData] = React.useState({
    catagories: [],
    subCatagories: [],
    products: [],
  });
  const history = useNavigate();
  const [search, setSearch] = React.useState("");
  React.useEffect(() => {
    if (search === "") {
      setData({
        catagories: [],
        subCatagories: [],
        products: [],
      });
    } else {
      fetchSearchData();
    }
  }, [search]);
  const fetchSearchData = async () => {
    try {
      let response = await searchProduct(search);
      setData(response);
    } catch (err) {
      console.log(err);
    }
  };
  const searchInputRef = useRef(null); // Ref for the search input element

  useEffect(() => {
    // Focus on the search input when the component mounts
    console.log("input ayaa", searchInputRef.current);
    searchInputRef.current.focus();
  }, [props]);
  return (
    <>
      <div
        style={{ zIndex: "999" }}
        className={`fixed bg-opacity-50 text-white top-0 left-0 bg-gray-200 h-full w-full ${
          !props.open && "hidden"
        } flex justify-center items-center`}
      >
        <div className="p-5 rounded bg-white">
          <div class="flex items-center justify-center">
            <div class="flex border-2 rounded">
              <input
                type="text"
                class="px-4 py-2 w-96 bg-transparent outline-white focus:outline-none border border-transparent focus:border-golden"
                value={search}
                ref={searchInputRef}
                autoFocus
                placeholder="Search...."
                style={{ color: "green" }}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    // Call your search function here
                    // searchTable();
                    setSearch(e.target.value);
                  }
                }}
              />
              <button class="flex items-center  justify-center px-4 border-l hover:bg-white hover:text-blue bg-golden">
                <svg
                  class="w-6 h-6 text-black"
                  fill="black"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" />
                </svg>
              </button>
            </div>
            <svg
              class="w-6 h-6 cursor-pointer cursor-pointer ml-5 text-black"
              fill="currentColor"
              viewBox="0 0 20 20"
              onClick={() => {
                props.setOpen(false);
              }}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
          <div
            className="rounded mt-5"
            style={{ overflow: "auto", maxHeight: "24rem", width: "100%" }}
          >
            {search != "" && data?.products?.length > 0 && (
              <div className="sticky top-0 bg-gray-600 text-white p-1 rounded">
                {" "}
                Products
              </div>
            )}

            <div className="grid grid-cols-1 divide-y divide-blue-300">
              {search != "" &&
                data.products.map((p, i) => (
                  <div
                    key={i}
                    onClick={(e) => {
                      props.setOpen(!props.open);
                      history(
                        `/shop/${p.pCategory.cName
                          .toLowerCase()
                          .split(" ")
                          .join("-")
                          .split("/")
                          .join("or")}/${p.pSubCategory.name
                          .toLowerCase()
                          .split(" ")
                          .join("-")
                          .split("/")
                          .join("or")}/${p.pName
                          .toLowerCase()
                          .split(" ")
                          .join("-")
                          .split("/")
                          .join("or")}-${p.modelNumber}`
                      );
                    }}
                    className="p-2 text-black hover:bg-gray-400 cursor-pointer flex flex-row space-x-4 overflow-ellipsis overflow-hidden"
                  >
                    {p.pImages && p.pImages.length > 0 && (
                      <img
                        className="h-8 object-contain"
                        src={p.pImages.length > 0 && p.pImages[0].url}
                      />
                    )}
                    <div>
                      {p.pName}-{p.modelNumber} in {p.pSubCategory.name}
                    </div>
                  </div>
                ))}
            </div>

            {search != "" && data.subCatagories.length > 0 && (
              <div className="sticky top-0 bg-gray-600 text-white p-1 rounded">
                Sub Catagories
              </div>
            )}

            <div className="grid grid-cols-1 divide-y divide-blue-300">
              {search != "" &&
                data.subCatagories.map((scat, i) => (
                  <div
                    key={i}
                    onClick={(e) => {
                      props.setOpen(!props.open);
                      history(
                        `/shop/${scat.catagory.cName
                          .toLowerCase()
                          .split(" ")
                          .join("-")
                          .split("/")
                          .join("or")}/${scat.name
                          .toLowerCase()
                          .split(" ")
                          .join("-")
                          .split("/")
                          .join("or")}`
                      );
                    }}
                    className="p-2 text-black hover:bg-gray-400 cursor-pointer flex flex-row space-x-4 overflow-ellipsis overflow-hidden"
                  >
                    {scat.catagory && scat.catagory.cImage && (
                      <img
                        className="h-8 object-contain"
                        src={scat.catagory.cImage}
                      />
                    )}
                    <div>
                      {scat.name} in {scat.catagory?.cName}
                    </div>
                  </div>
                ))}
            </div>
            {search != "" && data.catagories.length > 0 && (
              <div className="sticky top-0 bg-gray-600 text-white p-1 rounded">
                Catagories
              </div>
            )}

            <div className="grid grid-cols-1 divide-y divide-blue-300">
              {search != "" &&
                data.catagories.map((cat, i) => (
                  <div
                    key={i}
                    onClick={(e) => {
                      props.setOpen(!props.open);
                      history(
                        `/shop/${cat.cName
                          .toLowerCase()
                          .split(" ")
                          .join("-")
                          .split("/")
                          .join("or")}}`
                      );
                    }}
                    className="p-2 hover:bg-gray-400 cursor-pointer flex flex-row space-x-4 overflow-ellipsis overflow-hidden"
                  >
                    {cat && cat.cImage && (
                      <img className="h-8 object-contain" src={cat.cImage} />
                    )}
                    <div>{cat.cName}</div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
