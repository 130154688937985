import React, { Fragment, useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { customizeContext } from "./index";
import { isWishReq, unWishReq, isWish } from "./Mixins";
import { getAllCustomProducts } from "./fetchApi";
import InfiniteScroll from "react-infinite-scroll-component";
// const apiURL = process.env.REACT_APP_API+'/';

const SingleProduct = (props) => {
  const { data, dispatch } = useContext(customizeContext);
  const { products } = data;
  const history = useNavigate();

  /* WhisList State */
  const [wList, setWlist] = useState(
    JSON.parse(localStorage.getItem("wishList"))
  );
  let [skip, setSkip] = useState(0);
  const [noMore, setNoMore] = useState(true);
  useEffect(() => {
    fetchData();
  }, []);
  const { cat, subCat } = useParams();
  const fetchData = async () => {
    // dispatch({ type: "loading", payload: true });
    try {
      let responseData = await getAllCustomProducts(skip);
      setTimeout(() => {
        if (responseData && responseData.Products) {
          dispatch({
            type: "setProducts",
            payload: [...products, ...responseData.Products],
          });
          dispatch({ type: "loading", payload: false });
          setSkip(skip + 8);
          setNoMore(true);
        }
      }, 500);
    } catch (error) {
      console.log(error);
    }
  };
  const capitalize = (string) => {
    if (string !== undefined) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return string;
  };
  const COUNTER = 8;
  if (data.loading) {
    return Array(COUNTER).fill(
      <div className="relative col-span-1  m-2">
        <div class="h-full border-2 border-gray-200 rounded-lg overflow-hidden">
          <div class="h-48 bg-gray-400 md:h-36 w-full object-cover object-center"></div>
          <div class="p-6">
            <h2 class="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
            <h1 class="w-1/2 mb-4 h-6 animate-pulse bg-gray-500"></h1>
          </div>
        </div>
      </div>
    );
  }
  return (
    <Fragment>
      <InfiniteScroll
        className="m-2 md:mx-8 md:my-4 grid grid-cols-2 md:grid-cols-4 "
        dataLength={products.length}
        next={fetchData}
        hasMore={noMore}
        loader={Array(COUNTER).fill(
          <div className="relative col-span-1  m-2">
            <div class="h-full border-2 border-gray-200 rounded-lg overflow-hidden">
              <div class="h-44 bg-gray-400 md:h-36 w-full"></div>
              <div class="p-2 md:p-6">
                <h2 class="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
                <h1 class="w-1/2 mb-4 h-6 animate-pulse bg-gray-500"></h1>
              </div>
            </div>
          </div>
        )}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        {/* {products && products.length > 0 ? ( */}
        {products.map((item, index) => {
          return (
            <Fragment key={index}>
              <div className="relative col-span-1 m-2 hover:shadow hover:cursor-pointer">
                <div
                  className="h-full border-2 border-gray-200 rounded-lg overflow-hidden cursor-pointer"
                  onClick={(e) =>
                    history(
                      `/shop/${item.pCategory.cName
                        .toLowerCase()
                        .split(" ")
                        .join("-")
                        .split("/")
                        .join("or")}/${item.pSubCategory.name
                        .toLowerCase()
                        .split(" ")
                        .join("-")
                        .split("/")
                        .join("or")}/${item.pName
                        .toLowerCase()
                        .split(" ")
                        .join("-")
                        .split("/")
                        .join("or")}-${item.modelNumber}`
                    )
                  }
                >
                  <div
                    style={{ background: "#EFEEEC" }}
                    className="relative h-44   md:h-36 "
                  >
                    <img
                      style={{ pointerEvents: "none" }}
                      className="h-44 md:h-56  w-full  object-contain  object-center"
                      src={
                        item.pImages.length === 0
                          ? "dummyimage"
                          : item.pImages[0].url
                      }
                      alt=""
                    />
                    <div className="text-xs md:text-md font-semibold  text-white animate-bounce bottom-0 left-0 italic p-1 bg-red-600 absolute">
                      <span className="text-xs font-semibold">Flat </span> 20%
                      <span className="text-xs font-semibold">Off </span>
                    </div>
                  </div>
                  <div className="md:text-xs hidden md:block  text-center font-semibold text-white bg-red-600 width-full">
                    Discount on Diamond and Labour*
                  </div>
                  <div
                    style={{ fontSize: "8px" }}
                    className="md:hidden  text-center font-semibold text-white bg-red-600 width-full"
                  >
                    Discount on Diamond and Labour*
                  </div>
                  <div class="p-2 md:p-6">
                    <div className="flex items-center justify-between">
                      <h6 class="truncate text-gray-600 text-base mb-2">
                        {item.pName}
                      </h6>
                      <div className="flex items-center space-x-1">
                        <span>
                          <svg
                            className="w-4 h-4 fill-current text-yellow-700"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                            />
                          </svg>
                        </span>
                        <span className="text-gray-700">{item.rating}</span>
                      </div>
                    </div>
                    <h6 class="text-base md:text-md  m-0">₹{item.pPrice}.00</h6>
                    <span class="mb-2 md:mb-4 text-base md:text-md text-red-500 line-through">
                      ₹{Math.ceil((item.pPrice * 100) / 80)}.00
                    </span>
                  </div>
                  <div className="absolute top-0 right-0 mx-2 my-2 md:mx-4">
                    <svg
                      onClick={(e) => isWishReq(e, item._id, setWlist)}
                      className={`${
                        isWish(item._id, wList) && "hidden"
                      } w-5 h-5 md:w-6 md:h-6 cursor-pointer text-yellow-700 transition-all duration-300 ease-in`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                      />
                    </svg>
                    <svg
                      onClick={(e) => unWishReq(e, item._id, setWlist)}
                      className={`${
                        !isWish(item._id, wList) && "hidden"
                      } w-5 h-5 md:w-6 md:h-6 cursor-pointer text-yellow-700 transition-all duration-300 ease-in`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </Fragment>
          );
        })}
      </InfiniteScroll>
      {/* // ) : (
      //   <div className="col-span-2 md:col-span-3 lg:col-span-4 flex items-center justify-center py-24 text-2xl">
      //     No product found
      //   </div>
      // )} */}
    </Fragment>
  );
};

export default SingleProduct;
