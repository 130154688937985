import React, { Fragment, useState, useEffect, useContext } from "react";
import { Table, Container, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { isWishReq, unWishReq, isWish } from "../home/Mixins";
import { getRelatedProducts } from "./FetchApi";
import Slider from "react-slick";
import {Link} from 'react-router-dom'
const RelatedProducts = (props) => {
  const history = useNavigate();
  const [products, setProducts] = useState([]);
  const [wList, setWlist] = useState(
    JSON.parse(localStorage.getItem("wishList"))
  );
  useEffect(() => {
    fetchRelatedProducts();
  }, []);
  const fetchRelatedProducts = async () => {
    let result = await getRelatedProducts({
      pPrice: props.pPrice,
      subCatagory: props.pSubCatagory,
    });
    if (result.Products) {
      console.log(result.Products)
      setProducts(result.Products);
    }
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    initialSlide: 0,
    arrows:true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="md:text-left md:mx-4 font-bold text-xl">
        You may also like <hr />
      </div>

      <Slider className=" overflow-hidden p-4" {...settings}>
        {products.map((item, i) => (
          <Link
          to={`/shop/${item.category_url}/${item.sub_category_url}/${item.pName
          .toLowerCase()
          .split(" ")
          .join("-")
          .split("/")
          .join("or")}-${item.modelNumber}`}
          key={item._id} 
          className="relative col-span-1 mr-4 pr-6 no-underline">
            <div
              className=" border-1 border-gray-200 rounded-lg overflow-hidden cursor-pointer h-55"
              sx={{ height: "50vh" }}
            >
              <img
              
                className="h-40 bg-gray-400 md:h-44 w-full object-cover object-center"
                src={item.pImages.length > 0 && item.pImages[0].url}
                alt=""
              />
              <div class="p-2">
                <div className="flex items-center justify-between mt-1 cursor-pointer">
                  <h6 class="truncate text-gray-600 h-4 w-1/2 mb-1">
                    {item.pName}
                  </h6>
                  <div className="flex items-center space-x-1 cursor-pointer">
                    <span>
                      <svg
                        className="w-4 h-4 fill-current text-yellow-700"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                        />
                      </svg>
                    </span>
                    <span className="text-gray-700">{item.rating}</span>
                  </div>
                </div>
                <br></br>
                <h6 class="w-1/2 mb-2 h-4 text-black no-underline	">
                ₹{Math.ceil(item.pPrice)}
                </h6>
              </div>
              <div className="absolute top-0 right-0 mx-2 my-2 md:mx-4 pr-8">
                <svg
                  onClick={(e) => isWishReq(e, item._id, setWlist)}
                  className={`${
                    isWish(item._id, wList) && "hidden"
                  } w-3 h-3 md:w-4 md:h-4 cursor-pointer text-yellow-700 transition-all duration-300 ease-in`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                  />
                </svg>
                <svg
                  onClick={(e) => unWishReq(e, item._id, setWlist)}
                  className={`${
                    !isWish(item._id, wList) && "hidden"
                  } w-3 h-3 md:w-4 md:h-4 cursor-pointer text-yellow-700 transition-all duration-300 ease-in`}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </Link>
        ))}
      </Slider>
    </>
  );
};

export default RelatedProducts;
